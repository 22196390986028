/// <reference types="@types/google.maps" />

import { Component, OnInit, Input, Output, forwardRef, ElementRef, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { PGMapGeometry, PGMapGeometryPoint } from '../../models/map.model';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../../services/environment.service';
import { Observable } from 'rxjs';
import { PGLocationAddress, PGUtilities } from '../../pg-utilities';

/**
 * Controllo selezione geolocation.<br/>
 * Usa google maps per visualizzare la mappa ed effettuare ricerche. Quando il valore viene impostato, effettua una query per l'indirizzo e imposta eventuali campi slave presenti nella form: nazione, regione, provincia, citta, indirizzo, cap
 */

@Component({
    selector: 'app-pg-location-picker',
    templateUrl: './pg-location-picker.component.html',
    styleUrls: ['./pg-location-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgLocationPickerComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgLocationPickerComponent),
        multi: true,
    }]
})

export class PgLocationPickerComponent implements OnInit, ControlValueAccessor, Validator {

    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;
    @Input() placeholder:string;

    @Input() allowAdvanced:boolean;

    @Input() slaves:any;

    showAdvanced = false;

    @Output() setSlaves = new EventEmitter<PGLocationAddress>();

    @ViewChild('gmaps', { static: true }) gmapsElement: ElementRef;
    @ViewChild('searchbox', { static: true }) searchBoxElement: ElementRef;

    search:google.maps.places.SearchBox = null;

    value:PGMapGeometry = null;

    constructor(private changeDetectorRef: ChangeDetectorRef, private http:HttpClient, private environmentService:EnvironmentService) { }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.setValue(obj, null, 'skip');

        this.centerMap();
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || this.value != null)  ? null : {
            required: {
                valid: false
            }
        }
    };

    map:google.maps.Map = null;
    geocoder:google.maps.Geocoder = null;

    searchMarkers:Array<google.maps.Marker> = [];

    ngOnInit() {
        this.gmapsElement.nativeElement.addEventListener('click', (eventObj) => {
            // per qualche motivo, clickando la mappa il click si propaga fino alla label
            // la label di default triggera un qualche evento sull'elemento map, credo un focus
            // questo mandava la mappa fullscreen ad ogni click

            eventObj.preventDefault();
        })

        this.geocoder = new google.maps.Geocoder();

        this.map = new google.maps.Map(this.gmapsElement.nativeElement, {
            center: new google.maps.LatLng(0, 0),
            zoom: 1,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: true          
        });

        this.map.addListener('click', (eventObj:google.maps.IconMouseEvent) => {
            if(eventObj.placeId != null) {
                this.geocoder.geocode({ 
                    placeId: eventObj.placeId
                }, (data) => {
                    this.setValue(data[0].geometry.location, data[0]?.address_components);
                })
            }
            else {
                this.geocoder.geocode({
                    location: eventObj.latLng
                }, (data) => {
                    this.setValue(eventObj.latLng, data[0]?.address_components);
                })
            }
        });

        this.search = new google.maps.places.SearchBox(this.searchBoxElement.nativeElement);
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchBoxElement.nativeElement);

        this.map.addListener('bounds_changed', () => {
            this.search.setBounds(this.map.getBounds());
        });
  
        this.search.addListener('places_changed', () => {
            while (this.searchMarkers.length > 0) {
                this.searchMarkers[0].setMap(null);
                this.searchMarkers.splice(0, 1);
            }

            let places = this.search.getPlaces();
  
            if(places.length == 1) {
                this.map.setCenter(places[0].geometry.location);

                this.setValue(places[0].geometry.location, places[0].address_components);
            }
        });
    }

    isLoadingAddress = false;

    setValue(value:google.maps.LatLng|PGMapGeometryPoint, address?:Array<google.maps.GeocoderAddressComponent>, changes?:'skip'|'force') {        
        if(changes != null || !this.readonly) {
            if(value != null && value['_forceChanges']) { // HACK
                delete value['_forceChanges'];

                if(Object.keys(value).length == 0) {
                    this.setValue(null, null, 'force')
                }
                else {
                    if('coordinates' in value) {
                        value = new google.maps.LatLng(value.coordinates[0], value.coordinates[1])
                    }

                    this.geocoder.geocode({ 
                        location: value 
                    }, (data) => {
                        this.setValue(value, data[0].address_components, 'force')
                    })
                }

                return;
            }

            if(value == null) {
                this.value = null;
            }
            else {
                if(value instanceof google.maps.LatLng) {
                    value = new PGMapGeometryPoint([value.lat(), value.lng()])
                }
        
                this.value = PGMapGeometry.fromData(value);
    
            }

            this.drawGeometry();
    
            if(changes != 'skip') {
                if(this._onTouched != null) this._onTouched();
                if(this._onChange != null) this._onChange(this.value);

                if(changes == 'force') this.centerMap();

                if(address == null) {
                    this.setSlaves.emit({});
                }
                else {
                    this.isLoadingAddress = true;
    
                    this._getTimezone(this.value.coordinates).subscribe((timezone) => {
                        this.isLoadingAddress = false;
    
                        let _slavesObj = PGUtilities.parseAddressComponents(address, timezone)
    
                        this.changeDetectorRef.detectChanges();
    
                        this.setSlaves.emit(_slavesObj);
                    })
                }
            }    
        }
    }

    private _getTimezone(coordinates:Array<number>) {
        return new Observable<string>((observer) => {
            this.http.get('https://maps.googleapis.com/maps/api/timezone/json?location=' + coordinates[0] + '%2C' + coordinates[1] + '&timestamp=' + Math.floor(Date.now() / 1000) + '&key=' + this.environmentService.environment.GoogleMapsAPIKey).subscribe((data:any) => {
                if(data.error != null) {
                    observer.next(null);
                }
                else {
                    observer.next(data.timeZoneId);
                }

                observer.unsubscribe()
            })
        })
    }

    valueMarker:google.maps.Marker = null;

    drawGeometry() {
        if(this.valueMarker != null) {
            this.valueMarker.setMap(null);
            this.valueMarker = null;
        }

        if(this.value != null) {
            if('coordinates' in this.value) {
                this.valueMarker = new google.maps.Marker({ 
                    map: this.map, 
                    position: new google.maps.LatLng(this.value.coordinates[0], this.value.coordinates[1]),
                    zIndex: 10 
                });

                this.valueMarker.addListener('click', () => {
                    this.setValue(null);
                })
            }
        }
    }

    centerMap() {
        if(this.value != null) {
            if('coordinates' in this.value) {
                this.map.setCenter(new google.maps.LatLng(this.value.coordinates[0], this.value.coordinates[1]));
                this.map.setZoom(15);
            }
        }
    }

    getAdvancedValue(index:number) {
        if(this.value != null) {
            if('coordinates' in this.value) {
                return this.value.coordinates[index];
            }
        }
    }

    setAdvancedValue(index:number, val:string) {
        if(this.value == null) {
            this.value = new PGMapGeometryPoint([0, 0])
        }

        if('coordinates' in this.value) {
            let _coordinates:[number,number] = JSON.parse(JSON.stringify(this.value.coordinates))
            _coordinates[index] = parseFloat(val);

            this.geocoder.geocode({
                location: new google.maps.LatLng(_coordinates[0], _coordinates[1])
            }, (data) => {
                this.setValue(new PGMapGeometryPoint(_coordinates), data[0].address_components)
            })
        }
    }

    hasSlaves() {
        if(this.slaves != null) {
            for(let i in this.slaves) {
                if(this.slaves[i]) return true;
            }
        }
    }
}

