import { Input, OnChanges, ElementRef, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit, Directive, Injectable } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigResourceView } from "../models/config.resources.model";
import { ConfigProfileRoleActionsExtended, ConfigService } from "../services/config.service";
import { DataService, GetResourceDataOptions } from "../services/data.service";
import { SemanticsService } from "../services/semantics.service";
import { EnvironmentService } from "../services/environment.service";
import { PgFilterStatus } from "../pg-form/pg-filter-editor/pg-filter-editor.component";
import { LocalizationService } from "../services/localization.service";
import { NotificationsService } from "../services/notifications.service";
import { ConfigProfileRole } from "../models/config.profiles.model";

@Injectable({
    providedIn: 'root'
})
export class PGViewResourceManager {
    index: {
        [id:string]: any
    } = {};

    register(id:string, view: any, config?: any) {
        this.index[id] = {
            view: view,
            config: config
        }
    }

    getViewComponent(viewId:string) {
        if(this.index[viewId] == null) return null;
        else return this.index[viewId].view;
    }

    getConfigComponent(viewId:string) {
        if(this.index[viewId] == null) return null;
        else return this.index[viewId].config;
    }
}

export class PGViewResourceElementAction {
    id:string;
    label?:string;
    icon?:string;
    color?:string;
    isVisible?:(element:any) => void
    onAction?:(element:any) => void
    children?:Array<PGViewResourceElementAction>
}

export class PGViewResourceElementBadge {
    label:string;
    icon?:string;
    color?:string;
    isVisible?:(element:any) => void
}

@Directive()
export class PGViewResourceConfigComponent {
    @Input() view:ConfigResourceView<any> = null;
}

@Directive()
export abstract class PGViewResourceComponent implements OnInit, OnChanges {
    @Input() resourceId:string = null;
    @Input() relatedResource:string = null;
    @Input() relatedElement:string = null;

    @Input() filterStatus:PgFilterStatus = null;

    @Input() elementActions:Array<PGViewResourceElementAction> = null;
    @Input() elementBadges:Array<PGViewResourceElementBadge> = null;

    viewConfig:ConfigResourceView<any> = null;

    resourceName:string = null;

    actions:ConfigProfileRoleActionsExtended = null;
    permissions:ConfigProfileRole = null;

    withRelated:Array<string> = [];

    @Input() selectionMode:'single'|'multi';
    @Input() selectionField:string;
    @Input() selection:Array<string>;

    @Output() selectionChange = new EventEmitter<Array<string>>();

    @Input() readOnly:boolean = null;

    @Input() baseURL:string = null;

    unsavedDrafts:Array<{ id:string, description: string }> = [];

    getBaseURL() {
        if(this.baseURL == null) return '../..'
        else return this.baseURL
    }

    constructor(protected dataService: DataService, protected configService: ConfigService, protected semanticsService:SemanticsService, protected router:Router, protected route:ActivatedRoute, protected environmentService:EnvironmentService, protected modalService:NgbModal, protected localizationService:LocalizationService, protected notificationsService:NotificationsService) {
    }

    private _unsavedDraftsSubscription:Subscription = null;

    private _didNotChange = true;

    ngOnInit() {
        if(this._didNotChange) this.ngOnChanges();
    }

    ngOnChanges() {
        this._didNotChange = false;

        this.viewConfig = this.configService.getResourceViewConfig(this.resourceId);

        this.actions = this.configService.getResourceActionsExtended(this.resourceId);
        if(this.readOnly) {
            this.actions.create = false;
            this.actions.edit = false;
            this.actions.delete = false;
        }

        this.permissions = this.configService.getResourcePermissions(this.resourceId);

        this.resourceName = this.localizationService.translate('RESOURCES.' + this.resourceId + '.name');

        if(this.selection == null) this.selection = [];

        this.unsavedDrafts = [];

        if(this._unsavedDraftsSubscription != null) this._unsavedDraftsSubscription.unsubscribe();

        this._unsavedDraftsSubscription = this.dataService.getResourceDrafts(this.resourceId).subscribe((data) => {
            for(let i in data) {
                this.unsavedDrafts.push({ 
                    id: i, 
                    description: this.semanticsService.evaluateResourceSemantic(this.resourceId, 'title', data[i]) 
                })
            }
        })
    }

    beforeGetData(options:GetResourceDataOptions) {
        return options;
    }

    getData(offset:number, limit:number):Observable<Array<any>> {
        return new Observable<Array<any>>((observer) => {

            this.dataService.getResourceData(this.resourceId, this.beforeGetData({
                with: this.withRelated, 
                search: this.filterStatus != null ? this.filterStatus.search : null, 
                tags: this.filterStatus != null ? this.filterStatus.tags : null, 
                system_tags: this.filterStatus != null ? this.filterStatus.system_tags : null, 
                filter: this.filterStatus != null ? this.filterStatus.filter : null, 
                order: this.filterStatus != null ? this.filterStatus.order : null, 
                offset: offset, 
                limit: limit
            }), this.relatedResource, this.relatedElement).subscribe(data => {

                observer.next(data);
                observer.unsubscribe();
            })
        })
    }

    isSelected(item:any) {
        let _idField = this.selectionField;
        if(_idField == null) _idField = 'id';

        return this.selectionMode != null && this.selection.indexOf(item[_idField]) != -1;
    }

    toggleSelection(item:any) {
        let _idField = this.selectionField;
        if(_idField == null) _idField = 'id';

        let _selectionVal = item[_idField];

        if(this.selectionMode != null) {

            if(this.selectionMode == 'single') {
                if(this.selection.length != 1 || this.selection[0] != _selectionVal) {
                    this.selection.splice(0);
                    this.selection[0] = _selectionVal;

                    this.selectionChange.emit(this.selection);
                }
            }
            else {
                let _cIndex = this.selection.indexOf(_selectionVal);

                if(_cIndex != -1) {
                    this.selection.splice(_cIndex, 1);
                }
                else {
                    this.selection.push(_selectionVal);
                }

                this.selectionChange.emit(this.selection);
            }
        }
    }

    createNew() {
        this.configService.getResourceNewElement(this.resourceId, this.relatedResource, this.relatedElement).subscribe((newData) => {
            this.dataService.createDraftElement(this.resourceId, newData).subscribe(data => {
                this.router.navigate([this.getBaseURL() +'/form/' + this.resourceId + '/' + data.id], { relativeTo: this.route });
            });
        })
    }

    resumeDraft(id:string) {
        this.router.navigate([this.getBaseURL() +'/form/' + this.resourceId + '/' + id], { relativeTo: this.route });
    }

    abstract handleElementChange(id:string, data:any)
}

@Directive()
export abstract class PGViewResourceInfiniteComponent extends PGViewResourceComponent implements OnDestroy, AfterViewInit {
    dataRows:Array<any> = [];

    protected pageSize = 10;
    protected pageNum = 0;

    isLoadingData = false;
    isAtEnd = false;

    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

    ngAfterViewChecked() {
    }

    private _loadSubscription:Subscription = null

    // TODO: spostare in classe super, sulla getData
    onLoadData(data:Array<any>) {
        return new Observable<any>((observer) => {
            observer.next(data);
            observer.unsubscribe();
        })
    }

    loadData() {
        if(!this.isAtEnd && !this.isLoadingData) {
            this.isLoadingData = true;

            // ne chiedo uno in più per verificare se ce ne sono altri dopo quelli che verranno visualizzati

            this._loadSubscription = this.getData(this.pageSize * this.pageNum, this.pageSize + 1).subscribe((data) => {
                if(data == null) data = [];

                if(data.length <= this.pageSize) this.isAtEnd = true;
                data.splice(this.pageSize);

                this._loadSubscription = this.onLoadData(data).subscribe((data) => {
                    this._loadSubscription = null;

                    for(let _cData of data) {
                        this.dataRows.push(_cData);
                    }
                    
                    this.pageNum++;

                    this.isLoadingData = false;
                })
            }, (error) => {
                this.isLoadingData = false;
                this.isAtEnd = true;
            }) 
        }
    }

    reset() {
        if(this._loadSubscription) this._loadSubscription.unsubscribe();
        this.isLoadingData = false;
        this.pageNum = 0;
        this.isAtEnd = false;
        this.dataRows = [];

        this.loadData();
    }

    handleElementChange(id:string, data:any) {
        if(data == null) {
            this.reset()
        }
        else {
            for(let j = 0; j < this.dataRows.length; j++) {
                let _row = this.dataRows[j];
    
                if(_row.id == id) {
                    for(let i in data) {
                        if(i != 'id') {
                            _row[i] = null
                        }
                    }
    
                    this.dataService.getElementData(this.resourceId, id, this.withRelated).subscribe(data => {
                        this.dataRows[j] = data
                    })
                }
            }
        }
    }

    isElementProtected(element:any) {
        return this.configService.isElementProtected(this.resourceId, element)
    }

    // Non va bene triggerare automaticamente il reset sull'ngOnChanges perché le classi che ereditano potrebbero dover fare qualcosa prima del reset e dopo la super.ngOnChanges

    /*ngOnChanges() {
        super.ngOnChanges();

        this.reset();
    }*/
}