<div class="PGAdminRealmUsers">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h4 class="m-0">{{ 'pg-admin-realm-users.title' | locTranslate }}<ng-container *ngIf="realmId != null">: {{realmName}}</ng-container>
        </h4>
        <div *ngIf="resourceMode == 'admin'" class="d-flex align-items-center">
            <app-pg-select resource="User" [resourceMode]="resourceMode" [(ngModel)]="addUser" [display]="{ oneLine: true }"></app-pg-select>
            <button class="btn btn-primary ms-2" (click)="doAddUser()">
                <i class="fa-regular fa-user-plus"></i>
            </button>
        </div>
        <div *ngIf="resourceMode != 'admin'" class="d-flex align-items-center">
            <app-pg-copy-clipboard [options]="inviteOptions">
                <button class="btn btn-link ms-2">
                    <span>{{ 'pg-admin-realm-users.invite' | locTranslate }}</span>
                    <i class="fa-regular fa-user-plus"></i>
                </button>
            </app-pg-copy-clipboard>
        </div>
    </div>

    <ng-container *ngIf="userRealmData != null">
        <div *ngFor="let userRealm of userRealmData; let i = index" class="PGAdminRealmUsers-Item align-items-center border-bottom mb-2 pb-2 {{ i == 0 ? 'pt-2 border-top' : ''}}">
            <div class="d-flex justify-content-between">
                <div>
                    <h5 class="PGAdminRealmUsers-Item-User py-1 m-0">{{userRealm.related_user.id}} - {{userRealm.related_user.name}} {{userRealm.related_user.surname}}</h5>
                    <ng-container *ngIf="userRealm.status == 'accepted'">
                        <div class="PGAdminRealmUsers-Item-Roles mt-2">
                            <div>ROLES</div>
                            <app-pg-resource-association resourceId="Role" relatedResource="User" [relatedElement]="userRealm.related_user.id" [relationConfig]="{
                                type: 'N:N',
                                modelA: 'User',
                                modelB: 'Role',
                                joinTable: 'RoleUser'
                            }" [resourceSemantic]="idNameSemantic" [resourceMode]="resourceMode" [realmFilter]="getRealmFilter()"></app-pg-resource-association>
                        </div>
                        <div class="PGAdminRealmUsers-Item-Groups mt-2">
                            <div>GROUPS</div>
                            <app-pg-resource-association resourceId="Group" relatedResource="User" [relatedElement]="userRealm.related_user.id" [relationConfig]="{
                                type: 'N:N',
                                modelA: 'User',
                                modelB: 'Group',
                                joinTable: 'UserGroup'
                            }" [resourceSemantic]="idLabelSemantic" [resourceMode]="resourceMode"></app-pg-resource-association> <!-- qua  [realmFilter]="getRealmFilter()" non ci va, i gruppi di per sé sono per realm -->
                        </div>
                    </ng-container>
                </div>
                <div class="PGAdminRealmUsers-Item-Buttons border-start ps-2 ms-2 d-flex align-items-center">
                    <div>
                        <button class="btn btn-sm btn-{{userRealm.status == 'rejected' ? 'danger' : 'secondary'}}" [disabled]="isSavingUserRealm[userRealm.id] || userRealm.status == 'rejected'" (click)="setUserRealmStatus(userRealm.id, 'rejected')">
                            <i class="fa fa-times"></i>
                            <span>{{ 'pg-admin-realm-users.rejected' | locTranslate }}</span>
                        </button>
                        <button class="ms-2 btn btn-sm btn-{{userRealm.status == 'accepted' ? 'primary' : 'secondary'}}" [disabled]="isSavingUserRealm[userRealm.id] || userRealm.status == 'accepted'" (click)="setUserRealmStatus(userRealm.id, 'accepted')">
                            <i class="fa fa-check"></i>
                            <span>{{ 'pg-admin-realm-users.accepted' | locTranslate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    
        <app-pg-loading *ngIf="isLoading"></app-pg-loading>
        <app-pg-view-more [hidden]="atEnd || isLoading" (viewMore)="loadMoreData()"></app-pg-view-more>
    </ng-container>
</div>