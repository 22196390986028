<div class="PGRealmInvite text-center">
    <div *ngIf="isLoggedIn() && realm != null">
        <app-pg-loading *ngIf="isRequestingAddRealm" [isOverlay]="true"></app-pg-loading>
        <h3 class="mb-3">{{ 'pg-realm-invite.title' | locTranslate }} {{realm.name}}</h3>
        <div *ngIf="realm.logo" class="PGRealmInvite-Logo mb-3">
            <app-pg-image-autosize [src]="realm.logo" fit="contain"></app-pg-image-autosize>
        </div>
        <div *ngIf="hasContacts()" class="PGRealmInvite-Contacts mb-3">
            <div *ngIf="realm.contacts.email">
                <i class="fa-regular fa-envelope text-secondary me-1"></i>
                <a href="mailto:{{realm.contacts.email}}">{{realm.contacts.email}}</a>
            </div>
            <div *ngIf="realm.contacts.phone">
                <i class="fa-regular fa-phone text-secondary me-1"></i>
                <a href="phone:{{realm.contacts.phone}}">{{realm.contacts.phone}}</a>
            </div>
            <div *ngIf="realm.contacts.website">
                <i class="fa-regular fa-globe text-secondary me-1"></i>
                <a target="_blank" href="{{realm.contacts.website}}">{{realm.contacts.website}}</a>
            </div>
        </div>
        <div>
            <button *ngIf="!getRealmStatus()" class="btn btn-primary" [disabled]="isRequestingAddRealm" (click)="requestAddRealm()">
                <i class="fa-regular fa-user-plus"></i>
                <span>{{ 'pg-realm-invite.request-access' | locTranslate }}</span>
            </button>

            <div *ngIf="getRealmStatus(); let status">
                <div *ngIf="status == 'available'" class="text-primary">{{ 'pg-realm-invite.status-available' | locTranslate }}</div>
                <div *ngIf="status == 'unavailable'" class="text-danger">{{ 'pg-realm-invite.status-unavailable' | locTranslate }}</div>
                <div *ngIf="status == 'pending'">{{ 'pg-realm-invite.status-pending' | locTranslate }}</div>
            </div>
        </div>
    </div>
</div>
