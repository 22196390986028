<app-pg-profile-editor></app-pg-profile-editor>

<ng-template #loginTemplate>
    <app-pg-loading *ngIf="isLoading" [isOverlay]="true"></app-pg-loading>
    <div class="modal-body">
        <div class="PGLoginHandler">
            <ng-container *ngIf="passwordRecover">
                <div class="PGLoginHandler-Title mb-3">
                    <h3 class="mb-0">{{ 'pg-login-handler.title-password-recover' | locTranslate }}</h3>
                    <div>
                        <app-pg-help></app-pg-help>
                    </div>
                    <div class="ms-1">
                        <app-pg-language-selector></app-pg-language-selector>
                    </div>
                </div>

                <p class="text-muted mb-3">
                    {{'pg-login-handler.subtitle-password-recover' | locTranslate}}
                </p>

                <ng-container *ngIf="!loginOTPSent">
                    <div class="form-group">
                        <label>
                            <b>{{ (disablePhone ? 'pg-login-handler.input-email' : 'pg-login-handler.input-contact') | locTranslate }}</b>
                        </label>
                        <app-pg-recipient [(ngModel)]="loginOTPRecipient" [readonly]="loginOTPSent" (viaChange)="loginOTPVia = $event" (keyup)="onLoginOTPKeyUp($event)"></app-pg-recipient>
                    </div>
                    <div *ngIf="loginOTPSendError" class="form-group">
                        <div class="text-danger">{{ 'pg-login-handler.otp-failed' | locTranslate }}</div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="!canSendOTP()" (click)="sendOTP()">
                        <i class="fa-regular fa-paper-plane"></i>
                        <span>{{ 'pg-login-handler.button-otp' | locTranslate }}</span>
                    </button>
                </ng-container>
                <ng-container *ngIf="loginOTPSent">
                    <div class="mb-3">
                        <button class="btn btn-link btn-sm p-0" (click)="resetOTP()">
                            <i class="fa-regular fa-chevron-left"></i>
                            <span>{{ 'pg-login-handler.edit-contact' | locTranslate }}</span>
                        </button>
                    </div>
                    <div class="form-group">
                        <div class="text-success">{{ 'pg-login-handler.otp-sent' | locTranslate:{ recipient: loginOTPRecipient, via: loginOTPVia == 'email' ? 'email' : 'SMS' } }}</div>
                    </div>
                    <div class="form-group">
                        <label>
                            <b>{{ 'pg-login-handler.input-otp' | locTranslate }}</b>
                        </label>
                        <input class="form-control" [(ngModel)]="loginOTPVerify" (keyup)="onLoginOTPKeyUp($event)"/>
                    </div>
                    <div class="form-group">
                        <div *ngIf="loginError" class="text-danger">{{ 'pg-login-handler.check-failed' | locTranslate }}</div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="!canLoginOTP()" (click)="doPasswordRecover()">
                        <i class="fa-regular fa-sign-in-alt"></i>
                        <span>{{ 'pg-login-handler.button-check' | locTranslate }}</span>
                    </button>
                </ng-container>

            </ng-container>

            <ng-container *ngIf="!passwordRecover">
                <div class="PGLoginHandler-Title mb-3">
                    <h3 class="mb-0">{{ 'pg-login-handler.title-login' | locTranslate }}</h3>
                    <div>
                        <app-pg-help></app-pg-help>
                    </div>
                    <div class="ms-1">
                        <app-pg-language-selector></app-pg-language-selector>
                    </div>
                </div>
    
                <p *ngIf="customMessage != null" class="text-muted mb-3">
                    {{customMessage}}
                </p>
    
                <div *ngIf="availableModes.length > 1" class="mb-3 d-flex justify-content-around">
                    <button *ngFor="let mode of availableModes" class="btn btn-sm btn-white py-0 {{mode == currentMode ? 'btn-white-selected' : ''}}" (click)="currentMode = mode">{{ 'pg-login-handler.link-' + mode | locTranslate }}</button>
                </div>
    
                <hr/>
    
                <ng-container *ngIf="currentMode == 'default'">
                    <div class="form-group">
                        <label>
                            <b>{{ 'pg-login-handler.input-email' | locTranslate }}</b>
                        </label>
                        <input type="text" class="form-control" ngbAutofocus [(ngModel)]="loginUsername" placeholder="email@website.com" (keyup)="onLoginDefaultKeyUp($event)"/>
                    </div>
                    <div class="form-group mb-0">
                        <label>
                            <b>{{ 'pg-login-handler.input-password' | locTranslate }}</b>
                        </label>
                        <input type="password" class="form-control" [(ngModel)]="loginPassword" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" (keyup)="onLoginDefaultKeyUp($event)"/>
                    </div>
                    <div>
                        <button class="btn btn-link btn-sm p-0" (click)="startPasswordRecover()">{{ 'pg-login-handler.password-recover' | locTranslate }}</button>
                    </div>
                    <div class="form-group">
                        <div *ngIf="loginError" class="text-danger">{{ 'pg-login-handler.login-failed' | locTranslate }}</div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="!canLoginDefault()" (click)="doLoginDefault()">
                        <i class="fa-regular fa-sign-in-alt"></i>
                        <span>{{ 'pg-login-handler.button-login' | locTranslate }}</span>
                    </button>
                    <button *ngIf="hasGoogleLogin" class="btn btn-outline-primary btn-block" (click)="doGoogleLogin()">
                        <i class="fa-brands fa-google"></i>
                        <span>{{ 'pg-login-handler.button-login-with-google' | locTranslate }}</span>
                    </button>
                </ng-container>
    
                <ng-container *ngIf="currentMode == 'otp'">
                    <ng-container *ngIf="!loginOTPSent">
                        <div class="form-group">
                            <label>
                                <b>{{ (disablePhone ? 'pg-login-handler.input-email' : 'pg-login-handler.input-contact') | locTranslate }}</b>
                            </label>
                            <app-pg-recipient [(ngModel)]="loginOTPRecipient" [readonly]="loginOTPSent" (viaChange)="loginOTPVia = $event" (keyup)="onLoginOTPKeyUp($event)"></app-pg-recipient>
                        </div>
                        <div *ngIf="loginOTPSendError" class="form-group">
                            <div class="text-danger">{{ 'pg-login-handler.otp-failed' | locTranslate }}</div>
                        </div>
                        <button class="btn btn-primary btn-block" [disabled]="!canSendOTP()" (click)="sendOTP()">
                            <i class="fa-regular fa-paper-plane"></i>
                            <span>{{ 'pg-login-handler.button-otp' | locTranslate }}</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="loginOTPSent">
                        <div class="mb-3">
                            <button class="btn btn-link btn-sm p-0" (click)="resetOTP()">
                                <i class="fa-regular fa-chevron-left"></i>
                                <span>{{ 'pg-login-handler.edit-contact' | locTranslate }}</span>
                            </button>
                        </div>
                        <div class="form-group">
                            <div class="text-success">{{ 'pg-login-handler.otp-sent' | locTranslate:{ recipient: loginOTPRecipient, via: loginOTPVia == 'email' ? 'email' : 'SMS' } }}</div>
                        </div>
                        <div class="form-group">
                            <label>
                                <b>{{ 'pg-login-handler.input-otp' | locTranslate }}</b>
                            </label>
                            <input class="form-control" [(ngModel)]="loginOTPVerify" (keyup)="onLoginOTPKeyUp($event)"/>
                        </div>
                        <div class="form-group">
                            <div *ngIf="loginError" class="text-danger">{{ 'pg-login-handler.check-failed' | locTranslate }}</div>
                        </div>
                        <button class="btn btn-primary btn-block" [disabled]="!canLoginOTP()" (click)="doLoginOTP()">
                            <i class="fa-regular fa-sign-in-alt"></i>
                            <span>{{ 'pg-login-handler.button-check' | locTranslate }}</span>
                        </button>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="currentMode == 'code'">
                    <div class="form-group">
                        <label>
                            <b>{{ 'pg-login-handler.input-code' | locTranslate }}</b>
                        </label>
                        <input class="form-control" [(ngModel)]="loginCode" (keyup)="onLoginCodeKeyUp($event)"/>
                    </div>
                    <div class="form-group">
                        <div *ngIf="loginError" class="text-danger">{{ 'pg-login-handler.check-failed' | locTranslate }}</div>
                    </div>
                    <button class="btn btn-primary btn-block" [disabled]="!canLoginCode()" (click)="doLoginCode()">
                        <i class="fa-regular fa-sign-in-alt"></i>
                        <span>{{ 'pg-login-handler.button-check' | locTranslate }}</span>
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #acceptTemplate>
    <app-pg-loading *ngIf="isLoading" [isOverlay]="true"></app-pg-loading>
    <div class="modal-body">
        <div class="PGLoginHandler">
            <h4 class="mb-3">{{'pg-login-handler.title-accept' | locTranslate}}</h4>
            <hr/>
            <p class="mb-3">{{'pg-login-handler.subtitle-accept' | locTranslate}}</p>

            <div class="py-3">
                <div *ngIf="!hasUserAcceptedPrivacyPolicy" class="form-group d-flex">
                    <app-pg-switch [(ngModel)]="inputAcceptPrivacyPolicy" [small]="true"></app-pg-switch>
                    <span class="ms-2" [innerHTML]="'pg-login-handler.input-accept-privacy-policy' | locTranslate:{ url: privacyPolicyURL }"></span>
                </div>
    
                <div *ngIf="!hasUserAcceptedTermsAndConditions" class="form-group d-flex">
                    <app-pg-switch [(ngModel)]="inputAcceptTermsAndConditions" [small]="true"></app-pg-switch>
                    <span *ngIf="supplierMode" class="ms-2" [innerHTML]="'pg-login-handler.input-accept-supplier-terms-and-conditions' | locTranslate:{ url: termsAndConditionsURL }"></span>
                    <span *ngIf="!supplierMode" class="ms-2" [innerHTML]="'pg-login-handler.input-accept-terms-and-conditions' | locTranslate:{ url: termsAndConditionsURL }"></span>
                </div>
            </div>

            <hr/>

            <div class="text-end">
                <button class="btn btn-primary" [disabled]="!canAccept()" (click)="doAccept()">
                    <i class="fa-regular fa-check"></i>
                    <span>{{ 'pg-login-handler.button-accept' | locTranslate }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>