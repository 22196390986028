<ng-template #weatherTemplate let-weather="weather" let-aq="aq">
    <div class="PGWeather-Element-Info-Inner px-0 py-1">
        <div class="PGWeather-Element-Info-Display">
            <div [innerHTML]="getWeatherDisplay(weather) | safe:'html'"></div>
            <div>
                <span class="d-block small">{{getWeatherDescription(weather)}}</span>
            </div>
        </div>
        <div class="PGWeather-Element-Info-Values ps-3">
            <div class="PGWeather-Element-Info-Temperature">
                <div>{{getWeatherTemperature(weather, 'min')}} °C <i class="text-info far fa-thermometer-quarter"></i></div>
                <div>{{getWeatherTemperature(weather, 'max')}} °C <i class="text-danger far fa-thermometer-full"></i></div>
            </div>
            <div class="PGWeather-Element-Info-Humidity">
                <div>{{getWeatherHumidity(weather)}} % <i class="text-muted far fa-humidity"></i></div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="aq != null">
        <hr class="my-1 w-100"/>
        <div class="PGWeather-Element-Info-AQ px-2 py-1 d-flex justify-content-between">
            <div>AQI</div>
            <div *ngIf="getAirQualityIndex(aq); let aqi" class="text-end">
                <div class="badge text-white" [style.backgroundColor]="getAQIColor(aqi)">{{aqi}}</div>
            </div>
        </div>
    </ng-container>
</ng-template>

<div class="container-fluid PGWeather {{hasFullForecast() ? 'PGWeather--Forecast' : ''}}">
    <ng-container *ngFor="let location of locations">
        <div class="row PGWeather-Element">
            <div class="col {{hasFullForecast() ? 'col-12' : ''}} PGWeather-Element-Location">{{location.split(',')[0]}}</div>
            <div *ngIf="!hasFullForecast()" class="col PGWeather-Element-Info {{forecast == 'partial' ? 'PGWeather-Element-Info--HasPartial' : ''}}">
                <app-pg-loading *ngIf="currentWeather[location].isLoading"></app-pg-loading>
                <ng-container *ngIf="!currentWeather[location].isLoading">
                    <div *ngIf="currentWeather[location].data != null">
                        <ng-container *ngTemplateOutlet="weatherTemplate; context: { weather: currentWeather[location].data, aq: (currentAirQuality[location].data != null ? currentAirQuality[location].data.list : null) }"></ng-container>
                    </div>
                    <div *ngIf="forecast == 'partial'" class="PGWeather-Element-Info-Partial-Container ps-3 ms-3 border-start">
                        <ng-container *ngIf="!forecastWeather[location].isLoading && forecastWeather[location].data != null">
                            <ng-container *ngFor="let weather of forecastWeather[location].data; let i = index;">
                                <div *ngIf="i > 0 && i < 3" class="PGWeather-Element-Info-Partial">
                                    <div class="PGWeather-Element-Info-Partial-Header">{{getForecastDate(i)}}</div>
                                    <div *ngIf="weather != null" class="PGWeather-Element-Info-Partial-Inner">
                                        <div class="PGWeather-Element-Info-Partial-Display">
                                            <div [innerHTML]="getWeatherDisplay(weather.list) | safe:'html'"></div>
                                        </div>
                                        <div class="PGWeather-Element-Info-Partial-Values ms-2">
                                            <div class="PGWeather-Element-Info-Partial-Temperature">
                                                <div>{{getWeatherTemperature(weather.list, 'min')}} °C <i class="text-info far fa-thermometer-quarter"></i></div>
                                                <div>{{getWeatherTemperature(weather.list, 'max')}} °C <i class="text-danger far fa-thermometer-full"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="hasFullForecast()" class="col col-12">
                <div class="row">
                    <app-pg-loading *ngIf="forecastWeather[location].isLoading"></app-pg-loading>
                    <ng-container *ngIf="!forecastWeather[location].isLoading && forecastWeather[location].data != null">
                        <div *ngFor="let weather of forecastWeather[location].data; let i = index;" class="col PGWeather-Element-Info">
                            <div class="PGWeather-Element-Info-Header">{{getForecastDate(i)}}</div>
                            <button *ngIf="weather != null" class="btn btn-block btn-{{selectedForecastLocation == location && selectedForecastDay == i ? 'primary' : 'light'}}" (click)="toggleSelectedForecast(location, i)">
                                <ng-container *ngTemplateOutlet="weatherTemplate; context: { weather: weather.list, aq: (forecastAirQuality[location].data != null && forecastAirQuality[location].data[i] != null ? forecastAirQuality[location].data[i].list : null) }"></ng-container>
                            </button>
                        </div>
                        <div *ngIf="selectedForecastLocation == location" class="col-12 PGWeather-Element-Info-Graph">
                            <div class="border-top border-bottom border-primary">
                                <app-pg-graph [dataSet]="selectedForecastDataSet" graphMode="Line" [modeParams]="{ smooth: true }"></app-pg-graph>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>