<div class="PGGroupPicker">
    <div class="modal-header">
        <h5 class="modal-title">Inserisci i tag</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <input class="form-control" type="text" [ngModel]="selectedValue == null ? '' : selectedValue" (ngModelChange)="selectedValue = ($event == '' ? null : $event)" />
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.dismiss();">
            Annulla
        </button>
        <button class="btn btn-primary" (click)="modal.close(selectedValue);">
            OK
        </button>
    </div>
</div>
