import { HttpErrorResponse, HttpRequest } from "@angular/common/http";

export class PgHelpTicket {
    url:string = null;
    email:string = null;
    type?:string = null;
    message?:string = null;
    httpErrorLog?:Array<PgHelpHttpError> = null;
    jsErrorLog?:Array<PgHelpJsError> = null;
}

export class PgHelpHttpError {
    time:number
    request:HttpRequest<any>
    response:HttpErrorResponse
}

export class PgHelpJsError {
    time:number
    error:any
}