import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormatValidator, MaxLengthValidator, MaxValidator, MinValidator, ConstraintsValidator } from './validation.directive';

import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgDatePickerComponent } from './pg-date-picker/pg-date-picker.component';
import { PgDatetimePickerComponent } from './pg-datetime-picker/pg-datetime-picker.component';
import { PgHtmlEditorComponent } from './pg-html-editor/pg-html-editor.component';
import { PgLocationPickerComponent } from './pg-location-picker/pg-location-picker.component';
import { PgMultistateComponent, PgMultistateComponentState } from './pg-multistate/pg-multistate.component';
import { PgSelectComponent } from './pg-select/pg-select.component';
import { PgSwitchComponent } from './pg-switch/pg-switch.component';
import { PgTimePickerComponent } from './pg-time-picker/pg-time-picker.component';
import { PgFilePickerComponent } from './pg-file-picker/pg-file-picker.component';
import { PgRecipientComponent } from './pg-recipient/pg-recipient.component';
import { PgRatingComponent } from './pg-rating/pg-rating.component';
import { PgTimetableEditorComponent } from './pg-timetable-editor/pg-timetable-editor.component';
import { PgTicketsEditorComponent } from './pg-tickets-editor/pg-tickets-editor.component';
import { ValidationService } from './validation.service';
import { PgDateIntervalComponent } from './pg-date-interval/pg-date-interval.component';
import { PgPhoneComponent } from './pg-phone/pg-phone.component';
import { PgSurveyEditorComponent } from './pg-survey-editor/pg-survey-editor.component';
import { PgJsonEditorComponent } from './pg-json-editor/pg-json-editor.component';
import { PgSuggestComponent } from './pg-suggest/pg-suggest.component';
import { PgListEditorComponent } from './pg-list-editor/pg-list-editor.component';
import { PgSliderComponent } from './pg-slider/pg-slider.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PgEmailComponent } from './pg-email/pg-email.component';
import { PgUrlComponent } from './pg-url/pg-url.component';


@NgModule({
    declarations: [
        FormatValidator, 
        MinValidator, 
        MaxValidator, 
        MaxLengthValidator,
        ConstraintsValidator,
        PgSwitchComponent,
        PgLocationPickerComponent,
        PgMultistateComponent,
        PgMultistateComponentState,
        PgHtmlEditorComponent,
        PgTimePickerComponent,
        PgDatePickerComponent,
        PgDatetimePickerComponent,
        PgSelectComponent,
        PgFilePickerComponent,
        PgRecipientComponent,
        PgRatingComponent,
        PgTimetableEditorComponent,
        PgTicketsEditorComponent,
        PgDateIntervalComponent,
        PgPhoneComponent,
        PgSurveyEditorComponent,
        PgJsonEditorComponent,
        PgSuggestComponent,
        PgListEditorComponent,
        PgSliderComponent,
        PgEmailComponent,
        PgUrlComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiElementsModule,
        EditorModule
    ],
    exports: [
        FormatValidator, 
        MinValidator, 
        MaxValidator, 
        MaxLengthValidator,
        ConstraintsValidator,
        PgSwitchComponent,
        PgLocationPickerComponent,
        PgMultistateComponent,
        PgMultistateComponentState,
        PgHtmlEditorComponent,
        PgTimePickerComponent,
        PgDatePickerComponent,
        PgDatetimePickerComponent,
        PgSelectComponent,
        PgFilePickerComponent,
        PgRecipientComponent,
        PgRatingComponent,
        PgTimetableEditorComponent,
        PgTicketsEditorComponent,
        PgDateIntervalComponent,
        PgPhoneComponent,
        PgSurveyEditorComponent,
        PgJsonEditorComponent,
        PgSuggestComponent,
        PgListEditorComponent,
        PgSliderComponent,
        PgEmailComponent,
        PgUrlComponent
    ],
    providers: [
        ValidationService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' }
    ]
})
export class PgUiControlsModule { }
