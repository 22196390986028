import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EFItinerary, EFItineraryContent, EFItineraryContentResource } from '../../../models/experience.model';
import { PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { DataService } from '../../../services/data.service';
import { EFDataService } from '../../../services/ef-data.service';
import { LocalizationService } from '../../../services/localization.service';
import { NotificationsService } from '../../../services/notifications.service';
import { OptionMapsService } from '../../../services/option-maps.service';
import { AuthService } from '../../../services/auth.service';
import { SaveStatusRequest, SaveStatusService } from '../../../pg-ui-elements/save-status.service';
import { EnvironmentService } from '../../../services/environment.service';
import { PGUtilities } from '../../../pg-utilities';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PgFormComponent } from '../../../pg-form/pg-form/pg-form.component';

@Component({
  selector: 'app-forms-itinerary',
  templateUrl: './forms-itinerary.component.html',
  styleUrls: ['./forms-itinerary.component.scss']
})
export class FormsItineraryComponent implements OnChanges, OnDestroy { // TODO: ereditare da SingleFormComponent, andrebbero gestiti i campi tradotti e il salvataggio delle traduzioni

    @Input() elementId:string = null;
    @Input() returnUrl:string = null;

    @Input() viewOnly:boolean;

    @Output() valuesChange = new EventEmitter<any>();

    constructor(private dataService:DataService, private authService:AuthService, private efDataService:EFDataService, private optionMapsService:OptionMapsService, private localizationService:LocalizationService, private router:Router, private route:ActivatedRoute, private notificationsService:NotificationsService, private saveStatusService:SaveStatusService, private environmentService:EnvironmentService, private modalService:NgbModal) { }

    formLayout = new PgFormLayout([
        new PgFormField({ label: 'auto', type: 'string', name: 'name@ItineraryTranslation', locale: true, required: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'overtitle@ItineraryTranslation', locale: true, required: true, maxLength: 200 }),
        new PgFormField({ label: 'auto', type: 'string', name: 'subtitle@ItineraryTranslation', locale: true, required: true, maxLength: 200 }),
        new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
        new PgFormField({ label: 'auto', type: 'html', name: 'description@ItineraryTranslation', required: true, locale: true, maxLength: 3200 }),
        new PgFormField({ label: 'auto', type: 'file', multi: false, fileType: 'images', name: 'cover' }),
        new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'link_video' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'best_period@ItineraryTranslation', locale: true }),

        new PgFormField({ label: null, type: 'split', name: 'split_0' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'type' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'mean_of_transport', multi: true }),

        new PgFormField({ label: null, type: 'split', name: 'split_1' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'road_type', multi: true }),
        new PgFormField({ label: 'auto', type: 'select', name: 'surface_type', multi: true }),
        new PgFormField({ label: 'auto', type: 'boolean', name: 'road_signs' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'bike_type', multi: true }),

        new PgFormField({ label: null, type: 'split', name: 'split_2' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'difficulty', required: true }),
        new PgFormField({ label: 'auto', type: 'integer', name: 'duration' }),
        new PgFormField({ label: 'auto', type: 'file', name: 'file_gpx', multi: true, fileType: 'documents', fileOptions: { acceptString: '.gpx' } }),
        new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
        new PgFormField({ label: 'auto', type: 'select', name: 'physical_accessibility' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'cognitive_accessibility' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'blindness_accessibility' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'deafness_accessibility' }),
    ]);

    directionsService:google.maps.DirectionsService = null;

    private _initializeForm() {
        this.formLayout.getFieldByName('realm_id').default = this.authService.user.realm?.id;
        this.formLayout.getFieldByName('realm_id').visible = this.authService.user.availableRealms.length > 1;

        this.formLayout.getFieldByName('priority').visible = false;

        this.formLayout.getFieldLayout('mean_of_transport').condition = '$form.type == null || $form.type == "" || $form.type == "generico" || $form.type == "museale" || $form.type == "fluviale" || $form.type == "costiero"'
        this.formLayout.getFieldLayout('road_type').condition = '$form.type == "escursionistico" || $form.type == "cicloturistico"'
        this.formLayout.getFieldLayout('surface_type').condition = '$form.type == "escursionistico" || $form.type == "cicloturistico"'
        this.formLayout.getFieldLayout('road_signs').condition = '$form.type == "escursionistico" || $form.type == "cicloturistico"'
        this.formLayout.getFieldLayout('bike_type').condition = '$form.type == "cicloturistico"'

        //this.formLayout.getFieldLayout('split_0').display = { invisible: true }
        this.formLayout.getFieldLayout('split_1').display = { invisible: true }
        //this.formLayout.getFieldLayout('split_2').display = { invisible: true }

        let _accessibilityFields:Array<PgFormField> = [];
        let _groupFields = this.formLayout.formGroups[0].fields

        for(let _name of ['physical_accessibility','cognitive_accessibility','blindness_accessibility','deafness_accessibility']) {
            let _field = this.formLayout.getFieldByName(_name)

            let _index = _groupFields.indexOf(_field);

            _groupFields.splice(_index, 1)
            _accessibilityFields.push(_field)
        }

        this.formLayout.formGroups.push(new PgFormGroup({
            title: 'RESOURCES.Itinerary.fields.accessibility.label',
            description: 'RESOURCES.Itinerary.fields.accessibility.tooltip',
            collapsible: false,
            fields: _accessibilityFields
        }))

        let _selectOptions = this.optionMapsService.getResourceOptionMaps('Itinerary');

        for(let _cField of this.formLayout.fieldList) {
            let _cleanFieldName = _cField.name.replace(/@.*$/, '')

            if(_cField.label == 'auto') _cField.label = 'RESOURCES.Itinerary.fields.' + _cleanFieldName + '.label';
            if(_cField.tooltip == 'auto') _cField.tooltip = 'RESOURCES.Itinerary.fields.' + _cleanFieldName + '.tooltip';
            if(_cField.placeholder == 'auto') _cField.placeholder = 'RESOURCES.Itinerary.fields.' + _cleanFieldName + '.placeholder';

            if(_cField.type == 'select') {
                if(_cField.options == null) {
                    _cField.options = _selectOptions[_cField.name]
                }
            }
            else if(_cField.type == 'html' || (_cField.type == 'file' && _cField.multi) || _cField.type == 'location') {
                this.formLayout.getFieldLayout(_cField.name).display = { fullWidth: true }
            }
        }

        this.directionsService = new google.maps.DirectionsService();

        if(this.environmentService.environment.CustomFormConfig != null) {
            for(let _id of ['*', 'Itinerary']) {
                let _config = this.environmentService.environment.CustomFormConfig[_id];

                if(_config != null) {
                    for(let i in _config) {
                        let _field = this.formLayout.getFieldByName(i);

                        if(_field != null) {
                            for(let k in _config[i]) {
                                _field[k] = _config[i][k];
                            }
                        }
                    }
                }
            }
        }
    }

    private _isFormInitialized = false;

    isLoading = false;
    isFullyLoaded = false;

    private _translationByLanguage:{
        [language:string]: any
    } = null;

    ngOnChanges(): void {
        if(!this._isFormInitialized) {
            this._isFormInitialized = true;
            this._initializeForm()
        }

        this.formLayout.resetData();

        this.isLoading = true;
        this.isFullyLoaded = false;

        this._translationByLanguage = {};

        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
            let _cLanguageBaseObj:any = {
                language: _cLanguage
            }

            _cLanguageBaseObj['itinerary_id'] = this.elementId;

            this._translationByLanguage[_cLanguage] = _cLanguageBaseObj;
        }

        this._checkRemoveSaveRequest();

        this.formLayout.resetData();

        this._loadData().then((data) => {

            this._checkCreateSaveRequest()

            this.isLoading = false;
        
            this.formLayout.setData(data)

            this.itineraryData = new EFItinerary(data, this.efDataService, true);

            this.isFullyLoaded = this.itineraryData.isFullyLoaded;
            this.itineraryData.isFullyLoadedChange.subscribe((val) => {
                this.isFullyLoaded = val;
            })
        })
    }

    private _saveRequest:SaveStatusRequest = null;

    private _checkCreateSaveRequest() {
        if(!this.viewOnly) {
            let _openMode:'create'|'edit' = this.dataService.isDraftId(this.elementId) ? 'create' : 'edit';

            this._saveRequest = this.saveStatusService.createRequest(_openMode, 'forms-itinerary.itinerary-' + _openMode, (action) => {
                if(action == 'cancel') {
                    this.cancelEdit()
                }
                else if(action == 'save') {
                    this.saveData()
                }
            }, null, { save: true, cancel: true })
        }
    }

    private _checkRemoveSaveRequest() {
        if(this._saveRequest != null) this.saveStatusService.removeRequest(this._saveRequest)
    }

    ngOnDestroy() {
        this._checkRemoveSaveRequest();
    }

    private _loadData() {
        return new Promise<any>((resolve, reject) => {
            if(this.dataService.isDraftId(this.elementId)) {
                this.dataService.getDraftElementData('Itinerary', this.elementId).subscribe((data) => {
                    resolve(data)
                })
            }
            else {
                this.dataService.getElementData('Itinerary', this.elementId).subscribe((data) => {
                    this.dataService.getResourceData('ItineraryTranslation', null, 'Itinerary', this.elementId).subscribe((translData) => {
                        if(translData != null) {
                            for(let _cTransl of translData) {
                                this._translationByLanguage[_cTransl.language] = _cTransl;
                    
                                for(let i in _cTransl) {
                                    if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'language') {
                                        let _localeValue:any = {};
                    
                                        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                                            if(this._translationByLanguage[_cLanguage]) {
                                                _localeValue[_cLanguage] = this._translationByLanguage[_cLanguage][i];
                                            }
                                            else {
                                                _localeValue[_cLanguage] = {}
                                            }
                                        }
                    
                                        data[i + '@ItineraryTranslation'] = JSON.stringify(_localeValue);
                                    }
                                }
                            }
                        }

                        resolve(data)
                    })
                })
            }
        })
    }

    itineraryData:EFItinerary = null;

    resourceSemantics = {
        'Experience': '{{id}} - {{title}}',
        'Host': '{{id}} - {{name}}',
        'Eatery': '{{id}} - {{name}}',
        'ExperienceSupplier': '{{id}} - {{company_name}}',
        'Poi': '{{id}} - {{name}}'
    }

    addStopResourceOptions = [
        { value: 'Experience', text: 'Experience' },
        { value: 'Host', text: 'Host' },
        { value: 'Eatery', text: 'Eatery' },
        { value: 'ExperienceSupplier', text: 'ExperienceSupplier' },
        { value: 'Poi', text: 'Poi' },
        { value: 'External', text: 'External' },
    ]

    addStopResourceSelected:EFItineraryContentResource = null;
    addStopElementSelected:string = null;

    isAddingStopResourceElement = false;

    addStopResourceElement() {
        if(this.addStopResourceSelected == 'External') {
            this.editItineraryStopItem({ resource: this.addStopResourceSelected, element: this.addStopElementSelected }, true)

            this.addStopElementSelected = null;
        }
        else {
            this.isAddingStopResourceElement = true;

            this.efDataService.getResourceItineraryContent(this.addStopResourceSelected, this.addStopElementSelected, true).subscribe((data) => {
    
                this.editItineraryStopItem(data, true)

                this.isAddingStopResourceElement = false;  
                this.addStopElementSelected = null;
            })
        }
    }

    addServiceResourceOptions = [
        { value: 'Host', text: 'Host' },
        { value: 'Eatery', text: 'Eatery' },
        { value: 'ExperienceSupplier', text: 'ExperienceSupplier' },
        { value: 'Poi', text: 'Poi' },
    ]

    addServiceResourceSelected:EFItineraryContentResource = null;
    addServiceElementSelected:string = null;

    addServiceResourceElement() {
        this.itineraryData.addItemToServices({
            resource: this.addServiceResourceSelected,
            element: this.addServiceElementSelected
        })

        this.onValueChange({ collection: this.itineraryData.collection });
    }

    createModalType:'stop'|'service' = null;
    createModalResource:string = null;
    createModalData:any = null;

    createStopResourceElement() {
        this.createModalType = 'stop';
        this.createModalResource = this.addStopResourceSelected

        this.dataService.createDraftElement(this.addStopResourceSelected).subscribe((data) => {
            this._checkRemoveSaveRequest()
            this.createModalData = data;
        })
    }

    createServiceResourceElement() {
        this.createModalType = 'service';
        this.createModalResource = this.addServiceResourceSelected

        this.dataService.createDraftElement(this.addServiceResourceSelected).subscribe((data) => {
            this._checkRemoveSaveRequest()
            this.createModalData = data;
        })
    }


    onCreateElementFormReturn(val:{ action:string, id:string }) {
        this.dataService.deleteDraftElement(this.createModalResource, this.createModalData.id)

        if(val.action == 'insert') {
            if(this.createModalType == 'stop') {
                this.addStopElementSelected = val.id;
                this.addStopResourceElement();
            }
            else if(this.createModalType == 'service') {
                this.addServiceElementSelected = val.id;
                this.addServiceResourceElement();
            }
        }

        this.createModalType = null;
        this.createModalData = null;
        this._checkCreateSaveRequest() 
    }

    moveItineraryItemPrev(item:EFItineraryContent) {
        let _index = this.itineraryData.collection.indexOf(item);

        if(_index > 0) {
            this.itineraryData.collection.splice(_index, 1)
            this.itineraryData.collection.splice(_index - 1, 0, item)
        }

        this.onValueChange({ collection: this.itineraryData.collection });
    }

    moveItineraryItemNext(item:EFItineraryContent) {
        let _index = this.itineraryData.collection.indexOf(item);

        if(_index > -1 && _index < this.itineraryData.collection.length - 1) {
            this.itineraryData.collection.splice(_index, 1)
            this.itineraryData.collection.splice(_index + 1, 0, item)
        }

        this.onValueChange({ collection: this.itineraryData.collection });
    }

    deleteItineraryStopItem(item:EFItineraryContent) {
        let _index = this.itineraryData.collection.indexOf(item);

        if(_index > -1) {
            this.itineraryData.collection.splice(_index, 1)
        }

        this.onValueChange({ collection: this.itineraryData.collection });
    }

    deleteItineraryServiceItem(item:EFItineraryContent) {
        let _index = this.itineraryData.services.indexOf(item);

        if(_index > -1) {
            this.itineraryData.services.splice(_index, 1)
        }

        this.onValueChange({ services: this.itineraryData.services });
    }
    
    @ViewChild('editStopModal') editStopModal:ElementRef;
    editStopModalRef:NgbModalRef = null;

    editStopLayout = new PgFormLayout([
        new PgFormField({ label: '', type: 'select', multi: true, name: 'languages', readonly: true }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-title', type: 'string', name: 'title', required: true, locale: true, maxLength: 200 }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-image', type: 'file', name: 'image', required: true, fileType: 'images' }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-text', type: 'html', name: 'text', required: true, locale: true, maxLength: 3200 }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-tags', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-day', type: 'integer', name: 'day', required: true, min: 1 }),
        new PgFormField({ label: 'forms-itinerary.itinerary-item-edit-field-file_gpx', type: 'file', name: 'file_gpx', fileType: 'documents', fileOptions: { acceptString: '.gpx' } }),
    ])

    // NB: per ora solo le tappe in collection sono editabili, se questa cosa dovesse cambiare bisogna gestirla

    editStopModalMode:'insert'|'update'

    editItineraryStopItem(item:EFItineraryContent, andPush?:boolean) {
        // NB: questa roba si potrebbe fare una tantum nella ngOnInit

        for(let _field of this.editStopLayout.fieldList) {
            this.editStopLayout.getFieldLayout(_field.name).display = { fullWidth: true }
        }
        
        this.editStopLayout.getFieldLayout('languages').hidden = true;

        // fino a qua

        this.editStopLayout.setData(item, true)
        this.editStopLayout.getFieldByName('languages').value = this.formLayout.getFieldByName('languages').value;

        this.editStopModalMode = andPush ? 'insert' : 'update'

        this.editStopModalRef = this.modalService.open(this.editStopModal)

        this.editStopModalRef.result.then(() => {
            let _values = this.editStopLayout.getData()

            for(let i in _values) {
                item[i] = _values[i]
            }

            if(andPush) {
                this.itineraryData.addItemToCollection(item)
            }
            
            this.onValueChange({ collection: this.itineraryData.collection });
        }, () => {

        })
    }

    private _geolocationFields = ['region','province','city','address','zipcode']

    onValueChange(changes:any) {
        if(changes != null && typeof changes.type != 'undefined') {
            let _meanOfTransport = null;

            switch(changes.type) {
                case 'indoor':
                case 'escursionistico': _meanOfTransport = '["walking"]'; break;
                case 'cicloturistico': _meanOfTransport = '["bicycling"]'; break;
            }

            this.formLayout.setData({ mean_of_transport: _meanOfTransport });
            changes.mean_of_transport = _meanOfTransport;
        }

        if(changes != null && (typeof changes.mean_of_transport != 'undefined' || typeof changes.collection != 'undefined')) {
            if(typeof changes.mean_of_transport != 'undefined') this.itineraryData.mean_of_transport = PGUtilities.tryParseJSON(changes.mean_of_transport)

            this.itineraryData.geolocation = null;

            for(let _i of this._geolocationFields) {
                this[_i] = null;
            }

            if(this.itineraryData.collection.length > 0) {
                this.itineraryData.geolocation = this.itineraryData.collection[0].geolocation;
                
                let _coordinates = this.itineraryData.geolocation.coordinates;

                let _geocoder = new google.maps.Geocoder();
                _geocoder.geocode({
                    location: {
                        lat: _coordinates[0],
                        lng: _coordinates[1]
                    }
                }).then((data) => {
                    if(data.results != null && data.results.length > 0) {
                        let _parsed = PGUtilities.parseAddressComponents(data.results[0].address_components)

                        for(let _i of this._geolocationFields) {
                            this[_i] = _parsed[_i];
                        }
                    }
                }, () => {

                })
            }
        }

        if(this.dataService.isDraftId(this.elementId)) {
            this.dataService.saveDraftElementData('Itinerary', this.elementId, this.itineraryData.toData()).subscribe(() => {})
        }

        this.valuesChange.emit(this.itineraryData.toData())
    }

    isSaving = false;

    @ViewChild('formComponent') formComponent:PgFormComponent;

    saveData() {
        if(!this.isSaving && this.formComponent.validateForm()) {
            this.isSaving = true;

            let _values = this.formLayout.getData();

            let _keysToRemove = [];

            for(let i in _values) {
                let _cResource = i.split('@')[1];

                if(_cResource != null) {
                    _keysToRemove.push(i);

                    if(_values[i] != null) {
                        let _localeValue = JSON.parse(_values[i]);

                        for(let _cLanguage in _localeValue) {
                            if(this._translationByLanguage[_cLanguage] != null) {
                                this._translationByLanguage[_cLanguage][i.split('@')[0]] = _localeValue[_cLanguage]
                            }
                        }

                        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                            // prendo la prima lingua che ha un valore e la uso come valore del campo di base
                            if(_localeValue[_cLanguage] != null && _localeValue[_cLanguage] != '') {
                                _values[i.split('@')[0]] = _localeValue[_cLanguage];
                                break;
                            }
                        }
                    }
                    else {
                        _values[i.split('@')[0]] = null;
                    }
                }
            }

            for(let _cKey of _keysToRemove) {
                delete _values[_cKey];
            }
    
            for(let i in _values) {
                this.itineraryData[i] = _values[i];
            }

            _values = this.itineraryData.toData();
    
            this.dataService.saveElementData('Itinerary', this.elementId, _values).subscribe((data) => {
                if(data == null) {
                    this.isSaving = false;
                }
                else {
                    let _cAction:'update'|'insert' = 'update';
                    
                    if(_values.id != data.id) {
                        _cAction = 'insert'
                    }

                    this.dataService.saveElementTranslations(data.id, 'ItineraryTranslation', 'itinerary_id', data.languages, this._translationByLanguage).subscribe(() => {
                        this.isSaving = false;

                        if(data != null) {
                             this.notificationsService.addLocalNotification('forms-result-splash.action-text-' + _cAction, 'success', null, null, 3000)

                            this.router.navigate([this.returnUrl], { relativeTo: this.route })  
                        }
                    })
                }
            })
        }
    }

    cancelEdit() {
        if(!this.isSaving) {
            this.router.navigate([this.returnUrl], { relativeTo: this.route })
        }
    }

    onShowProduct(product:string) {
        // TODO: da completare?
        console.log(product);
    }

    /* RIMOSSA, mantengo il codice perché potrebbe tornare utile

    isCalculatingTravelDistanceTime = false;

    private _calculateTravelDistanceTime() {
        return new Promise<void>((resolve, reject) => {
            if(this.itineraryData.mean_of_transport == null) {
                resolve();
            }
            else {
                let _directionsParameters = this.itineraryData.getDirectionsParameters();

                if(_directionsParameters == null) resolve();
                else {
                    this.directionsService.route({
                        origin: _directionsParameters.origin,
                        destination: _directionsParameters.destination,
                        waypoints: _directionsParameters.waypoints,
                        travelMode: _directionsParameters.travelMode,
                        avoidFerries: true,
                    }, (result, status) => {
                        let _distance = 0;
                        let _time = 0;
        
                        if(status == 'OK' && result.routes != null && result.routes.length > 0) { 
                            for(let _leg of result.routes[0].legs) {
                                _distance += _leg.distance.value;
                                _time += _leg.duration.value;
                            }
                        }
        
                        this.itineraryData.travel_distance = null
                        this.itineraryData.travel_time = null
        
                        if(_distance > 0) {
                            this.itineraryData.travel_distance = _distance    
                        }
        
                        if(_time > 0) {
                            this.itineraryData.travel_time = _time 
                        }
        
                        resolve();
                    })
                }
            }
        })
    }

    getFormattedTravelDistance() {
        if(this.itineraryData.travel_distance != null && this.itineraryData.travel_distance > 0) return Math.ceil(this.itineraryData.travel_distance / 1000) + ' Km'
    }

    getFormattedTravelTime() {
        if(this.itineraryData.travel_time != null && this.itineraryData.travel_time > 0) {
            let _hours = Math.floor(this.itineraryData.travel_time / 3600).toString();
            if(_hours.length < 2) _hours = '0' + _hours;
            let _minutes = Math.floor((this.itineraryData.travel_time % 3600) / 60).toString();
            if(_minutes.length < 2) _minutes = '0' + _minutes;

            return _hours + ':' + _minutes;
        }
    }
    */
}
