import { Component, OnInit, ViewChild } from '@angular/core';
import { PgErrorsComponent } from '../../pg-help/pg-errors/pg-errors.component';
import { EnvironmentService } from '../../services/environment.service';
import { NotificationsService, PGNotification } from '../../services/notifications.service';
import { ClipboardService } from '../../pg-ui-elements/clipboard.service';

@Component({
  selector: 'app-pg-footer',
  templateUrl: './pg-footer.component.html',
  styleUrls: ['./pg-footer.component.scss']
})
export class PgFooterComponent implements OnInit {

    constructor(private notificationsService:NotificationsService, private environmentService:EnvironmentService, private clipboardService:ClipboardService) { }

    notifications:Array<PGNotification> = [];

    privacyPolicyURL:string = null;
    termsAndConditionsURL:string = null;

    noLinks = false;

    appLogo:string = null;
    appProvider:string = null;

    ngOnInit() {
        this.appLogo = this.environmentService.environment.AppLogo;
        this.appProvider = this.environmentService.environment.AppProvider;

        if(this.environmentService.environment.AppMode == 'kiosk' || this.environmentService.ElectronWrapper != null) {
            this.noLinks = true;
        }
        
        this.privacyPolicyURL = this.environmentService.getPrivacyPolicyURL()
        this.termsAndConditionsURL = this.environmentService.getTermsAndConditionsURL();
        
        for(let _notification of this.notificationsService.notifications) {
            if(_notification.type == 'system' || _notification.local) {
                this.notifications.push(_notification)
            }
        }
        
        this.notificationsService.onNotification.subscribe((change) => {
            if(change.operation == 'add') {
                if(change.notification.type == 'system' || change.notification.local) {
                    this.notifications.unshift(change.notification)
                }
            }
            else if(change.operation == 'del') {
                for(let i = 0; i < this.notifications.length; i++) {
                    if(this.notifications[i].id == change.notification.id) {
                        this.notifications.splice(i, 1);
                        break;
                    }
                }
            }
        })
    }

    removeNotification(notification:PGNotification) {
        let _index = this.notifications.indexOf(notification);

        if(_index != -1) {
            this.notifications.splice(_index, 1)
        }
    }

    hasSomething() {
        return this.hasErrors() || this.notifications.length > 0
    }

    hasErrors() {
        return (this.errorsComponent != null && this.errorsComponent.errors.length > 0);
    }

    @ViewChild('errorsComponent') errorsComponent:PgErrorsComponent

    getVersionsTable() {
        return this.environmentService.getVersionsTable();
    }

    getVersionString() {
        return this.environmentService.getVersionString();
    }

    copiedVersionsData = false;
    private _copiedVersionsDataTimeout = null;

    copyVersionsData() {
        clearTimeout(this._copiedVersionsDataTimeout)
        
        this.clipboardService.copyText(this.environmentService.getVersionsTable(true))

        this.copiedVersionsData = true;

        this._copiedVersionsDataTimeout = setTimeout(() => {
            this.copiedVersionsData = false;
        }, 1500)
    }
}
