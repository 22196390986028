import { Component } from '@angular/core';
import { PgFormField, PgFormLayout } from '../../../models/form.model';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../services/notifications.service';
import { SaveStatusService } from '../../../pg-ui-elements/save-status.service';
import { PgDirectoryData, PgFileData } from '../../../models/file.model';
import { AuthService } from '../../../services/auth.service';
import { SingleFormComponent } from '../single-form-main';
import { LocalizationService } from '../../../services/localization.service';
import { EnvironmentService } from '../../../services/environment.service';
import { OptionMapsService } from '../../../services/option-maps.service';

@Component({
  selector: 'app-forms-file',
  templateUrl: './forms-file.component.html',
  styleUrls: ['./forms-file.component.scss']
})
export class FormsFileComponent extends SingleFormComponent {

    constructor(protected dataService:DataService, protected authService:AuthService, protected localizationService:LocalizationService, protected router:Router, protected route:ActivatedRoute, protected notificationsService:NotificationsService, protected saveStatusService:SaveStatusService, protected optionMapsService:OptionMapsService, protected environmentService:EnvironmentService) {
        super(dataService, authService, localizationService, router, route, notificationsService, saveStatusService, optionMapsService, environmentService)
    }

    resourceId = 'File';

    elementData:any = null;

    randomValue = Math.random()

    formLayout = new PgFormLayout([
        new PgFormField({ label: 'auto', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'system_tags' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'directory_id', resource: 'Directory', readonly: true, resourceSemantic: '{{id}} - {{label}}' }),

        new PgFormField({ label: null, type: 'split', name: 'split_0' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'license' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'license_other' }),

        new PgFormField({ label: null, type: 'split', name: 'split_1' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
    ]);

    protected async afterInitializeForm() {
        this.formLayout.getFieldLayout('directory_id').display = { oneLine: true };  
        
        this.formLayout.getFieldLayout('license_other').condition = '$form.license == "other"';    
        this.formLayout.getFieldLayout('realm_id').condition = '$form.directory_id == null';    

        // NB: per ora metto sempre nascosto realm_id e uso quello corrente, qua è particolarmente importante perché nel caso di upload di directory ho necessità di fare un tot di GET per verificare l'esistenza di file e cartelle che prendono di defualt il current realm
        // probabilmente però è da fare così ovunque
        this.formLayout.getFieldByName('realm_id').visible = false

        this.formLayout.getFieldLayout('split_0').display = { invisible: true }
        this.formLayout.getFieldLayout('split_1').display = { invisible: true }
    }

    protected adaptLoadData(values: any) {
        if(values.license != null) {
            let _isIn = false;

            for(let _option of this.selectOptions['license']) {
                if(_option.value == values.license) {
                    _isIn = true;
                    break;
                }
            }

            if(!_isIn) {
                values.license_other = values.license
                values.license = 'other'
            }
        }

        return values
    }

    protected adaptSaveData(values: any) {
        if(values.license == 'other') {
            values.license = values.license_other;

            if(values.license_other != null) {
                delete values.license_other;
            }
        }

        return values
    }

    uploadFiles:Array<{
        progress:number,
        result:'success'|'error',
        file:File,
        directory_id?:string
    }> = []

    addUploadFiles(files:Array<File>) {
        if(files != null) {
            for(let i = 0; i < files.length; i++) {
                this.uploadFiles.push({
                    progress: 0,
                    result: null,
                    file: files[i]
                });
            }
        }

        this._checkCanSave()
    }

    removeUploadFile(index:number) {
        this.uploadFiles.splice(index, 1)

        this._checkCanSave()
    }

    private _checkCanSave() {
        if(this._saveRequest != null) {
            if(this.openMode == 'edit') {
                this._saveRequest.actions.save = true
            }
            else {
                this._saveRequest.actions.save = false;
                for(let _upload of this.uploadFiles) {
                    if(_upload.result != 'success') {
                        this._saveRequest.actions.save = true;
                        break;
                    }
                }
            }
        }
    }
    
    saveData() {
        if(this.openMode == 'edit') {
            super.saveData()
        }
        else {
            if(!this.isSaving) {
                this.isSaving = true;

                let _values = this.adaptSaveData(this.formLayout.getData());
    
                for(let _upload of this.uploadFiles) {
                    if(_upload.result != 'success') {
                        _upload.progress = 0;
                        _upload.result = null;
                    }
                }

                let _time = Date.now()

                console.log('Upload start')
                console.log(this.uploadFiles.length + ' files')

                this._checkSaveDirectories(_values).then(() => {

                    console.log('Directories', Date.now() - _time)

                    _time = Date.now()

                    this._checkSaveFiles(_values).then((hasErrors) => {

                        console.log('Files', Date.now() - _time)

                        this.isSaving = false;

                        if(!hasErrors) {
                            let _action:'update'|'insert' = 'update';
                            if(this.openMode == 'create') _action = 'insert'
        
                            this.notificationsService.addLocalNotification('forms-result-splash.action-text-' + _action, 'success', null, null, 3000)
        
                            this.router.navigate([this.returnUrl], { relativeTo: this.route })  
                        }
                    })
                })
            }
        }
    }

    private _saveDirectoryFromPath(path:string) {
        return path.replace(/[^\/]*$/, '')
    }

    private _getPathParent(path:string) {
        return path.replace(/[^\/]*\/$/, '')
    }

    private _getPathLabel(path:string) {
        let _split = path.split('/')
        return _split[_split.length - 2] // l'ultimo è stringa vuota
    }

    private async _checkSaveDirectories(values:any) {
        let _toCheckList:Array<string> = [];
        
        for(let _upload of this.uploadFiles) {
            let _dir = this._saveDirectoryFromPath(_upload.file.webkitRelativePath);
            if(_dir != null) {
                let _dirSplit = _dir.split('/')
                let _check = ''

                for(let _split of _dirSplit) {
                    if(_split != '') {
                        _check += _split + '/'

                        if(_toCheckList.indexOf(_check) == -1) _toCheckList.push(_check)
                    }
                }
            }
        }

        let _checkedIndex:{ [label:string]: PgDirectoryData } = {}

        if(values.directory_id != null) {            
            await new Promise<void>((resolve, reject) => {
                this.dataService.getElementData('Directory', values.directory_id).subscribe((data) => {
                    _checkedIndex[''] = data
      
                    resolve();
                })
            })
        }

        for(let _check of _toCheckList) {
            let _parentPath = this._getPathParent(_check)
            let _parentDir = _checkedIndex[_parentPath]

            if(_checkedIndex[_check] == null) {
                await new Promise<void>((resolve, reject) => {
                    this.dataService.getResourceData('Directory', { limit: 1000, filter: [
                        { field: 'parent_id', operator: '==', value: [ _parentDir?.id ]}
                    ]}).subscribe((data) => {
                        for(let _item of data) {
                            _checkedIndex[_parentPath + _item.label + '/'] = _item
                        }

                        resolve();
                    })
                })
            }

            for(let _item of _toCheckList) {
                let _checkParentPath = this._getPathParent(_item)
                let _checkParentDir = _checkedIndex[_checkParentPath];

                if(_parentPath == _checkParentPath) {
                    if(_checkedIndex[_item] == null) {
                        await new Promise<void>((resolve, reject) => {
                            this.dataService.postResourceData('Directory', {
                                parent_id: _checkParentDir?.id,
                                realm_id: values.realm_id,
                                label: this._getPathLabel(_item),
                                tags: values.tags
                            }).subscribe((data) => {
                                _checkedIndex[_item] = data
    
                                resolve();
                            })
                        })
                    }
                    else {
                        await new Promise<void>((resolve, reject) => {
                            this.dataService.putElementData('Directory', _checkedIndex[_item].id, {
                                tags: values.tags
                            }).subscribe((data) => {
                                _checkedIndex[_item] = data
    
                                resolve();
                            })
                        })
                    }
                }
            }
        }

        for(let _upload of this.uploadFiles) {
            if(_upload.file.webkitRelativePath != null) {
                let _dir = this._saveDirectoryFromPath(_upload.file.webkitRelativePath);
                if(_dir != '') {
                    _upload.directory_id = _checkedIndex[_dir].id
                }
            }
        }
    }

    private async _checkSaveFiles(values:any) {
        let _hasErrors = false;

        let _checkedIndex: { [directory:string]: Array<PgFileData> } = {}

        for(let i = 0; i < this.uploadFiles.length; i++) {
            let _upload = this.uploadFiles[i];

            if(_upload.result == null) {

                let _element = document.getElementById(this.randomValue + '_upload_' + i)
                if(_element != null) _element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                
                _upload.progress = 0.05;
                _upload.result = null;

                if(_upload.directory_id != null) {
                    if(_checkedIndex[_upload.directory_id] == null) {
                        await new Promise<void>((resolve, reject) => {
                            this.dataService.getResourceData('File', { limit: 1000, filter: [
                                { field: 'directory_id', operator: '==', value: [_upload.directory_id] }
                            ] }).subscribe((data) => {
                                _checkedIndex[_upload.directory_id] = data

                                resolve()
                            })
                        })
                    }

                    for(let _file of _checkedIndex[_upload.directory_id]) {
                        if(_file.nome == _upload.file.name) {
                            await new Promise<void>((resolve, reject) => {
                                this.dataService.deleteElement('File', _file.id).subscribe((data) => {
                                    resolve()
                                })
                            })
                        }
                    }
                }

                await new Promise<void>((resolve, reject) => {
                    this.dataService.postFile('File', _upload.file, values.group_id, values.realm_id).subscribe((status) => {
                        _upload.progress = 0.05 + status.progress * 0.85;
        
                        if(status.complete) {
                            if(status.data == null) 
                            {
                                _upload.progress = 1;
                                _upload.result = 'error';
                                resolve()
                            }
                            else {
                                let _putValues = JSON.parse(JSON.stringify(values))

                                if(_upload.directory_id != undefined) {
                                    _putValues.directory_id = _upload.directory_id
                                }

                                this.dataService.putElementData('File', status.data.id, _putValues).subscribe((data) => {
                                    _upload.progress = 1;
                                    _upload.result = 'success';
                                    resolve()
                                }, () => {
                                    _upload.progress = 1;
                                    _upload.result = 'error';
                                    resolve()
                                })
                            }
                        }
                    }, () => {
                        _upload.progress = 1;
                        _upload.result = 'error';
                        resolve()
                    });
                })

                if(_upload.result == 'error') _hasErrors = true;
            }
        }

        return _hasErrors
    }
    
    // TODO: gestire crop image

    /*cropImage(file:PgFile) {
        let _modalRef = this.modalService.open(PgImageCropModalComponent);
        _modalRef.componentInstance.imageURL = file.url;

        _modalRef.result.then((result) => {
            if(result != null) {

                let _binaryString = atob(result.split(',')[1])
                let _byteArray = [];

                for(var i = 0; i < _binaryString.length; i++) {
                    _byteArray.push(_binaryString.charCodeAt(i));
                }
                
                this.uploadFile(new File([new Uint8Array(_byteArray)], file.nome_file))
            }
        }, () => {

        })
    }*/
}

