<div *ngIf="hasExtendedActions() && (actions.book || !isElementProtected())" (click)="$event.stopPropagation()" class="PGResourceElementMenu" ngbDropdown>
    <app-pg-loading *ngIf="isRunningAction" [isOverlay]="true"></app-pg-loading>
    <button ngbDropdownToggle class="btn btn-white">
        <span *ngIf="isLarge" class="me-2">{{ 'pg-resource-element-menu.button' | locTranslate }}</span>
        <i class="fa-regular fa-ellipsis-v"></i>
    </button>
    <div ngbDropdownMenu class="PGResourceElementMenu-Menu">
        <ng-container *ngIf="actions.book">
            <div *ngIf="!elementData.bookable" class="dropdown-item disabled">
                <i class="fa-regular fa-book"></i><span>{{ 'pg-resource-element-menu.not-bookable' | locTranslate }}</span>
            </div>
            
            <div *ngIf="elementData.bookable" class="dropdown-item" (click)="runAction('book')">
                <i class="fa-regular fa-book"></i><span>{{ getActionLabel('book') | locTranslate }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!isElementProtected()">
            <div *ngIf="actions.edit" class="dropdown-item" (click)="runAction('edit')">
                <i class="fa-regular fa-edit"></i><span>{{ getActionLabel('edit') | locTranslate }}</span>
            </div>
            <app-pg-confirm-action *ngIf="actions.delete" (confirmed)="runAction('delete')">
                <div class="dropdown-item text-danger">
                    <i class="fa-regular fa-trash"></i><span>{{ getActionLabel('delete') | locTranslate }}</span>
                </div>
            </app-pg-confirm-action>
            
            <ng-content></ng-content>

            <div class="dropdown-divider"></div>

            <ng-container *ngIf="actions.book && canEditField('bookable')">                
                <div class="dropdown-item d-flex justify-content-between" (click)="runAction('bookable', !elementData.bookable)">
                    <div>
                        <i class="fa-regular fa-book"></i><span>{{ 'pg-resource-element-menu.toggle-bookable' | locTranslate }}</span>
                    </div>
                    <div class="ps-2">
                        <app-pg-switch [small]="true" [ngModel]="elementData.bookable"></app-pg-switch>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="actions.publish && canEditField('published')">
                <div *ngIf="!elementData.published && !canBePublished()" class="dropdown-item disabled">
                    <i class="fa-regular fa-eye"></i><span>{{ 'pg-resource-element-menu.not-publishable' | locTranslate }}</span>
                </div>
                <div *ngIf="elementData.published || canBePublished()" class="dropdown-item d-flex justify-content-between" (click)="runAction('published', !elementData.published)">
                    <div>
                        <i class="fa-regular fa-eye"></i><span>{{ 'pg-resource-element-menu.toggle-published' | locTranslate }}</span>
                    </div>
                    <div class="ps-2">
                        <app-pg-switch [small]="true" [ngModel]="elementData.published"></app-pg-switch>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngFor="let connector of ['DHM','TDH']">
                <ng-container *ngIf="canPublishOn(connector)">
                    <div *ngIf="!canBePublishedOn(connector)" class="dropdown-item disabled">
                        <i class="fa-regular fa-eye"></i><span>{{ 'pg-resource-element-menu.not-connector-publishable' | locTranslate:{ connector: connector } }}</span>
                    </div>
                    <ng-container *ngIf="canBePublishedOn(connector)">
                        <div class="dropdown-item d-flex justify-content-between" (click)="runAction('connector-publish', true, connector)">
                            <div>
                                <div class="d-flex">
                                    <div>
                                        <i class="fa-regular fa-cloud-arrow-up"></i>
                                    </div>
                                    <div>
                                        <div>
                                            <span>{{ 'pg-resource-element-menu.connector-publish' | locTranslate:{ connector: connector } }}</span>
                                        </div>
                                        <div *ngIf="getLastPublished(connector); let date" class="small">
                                            <span class="text-muted">{{ 'pg-resource-element-menu.connector-last-published' | locTranslate }} {{date | locFormat:'datetime'}}</span>
                                        </div>
                                    </div>
                                </div>
    
                                <div *ngIf="hasPublishWarning(connector)" class="mt-1">
                                    <small class="alert alert-warning m-0 px-2 py-1 text-wrap">{{ 'pg-resource-element-menu.warning-connector-publish' | locTranslate:{ connector: connector } }}</small>
                                </div>                                
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div *ngIf="canEditField('category')" class="dropdown-item d-flex justify-content-between" (click)="runAction('category', elementData.category)">
                <div>
                    <i class="fa-regular fa-list"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.category.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getCategoryLabel(elementData.category) || '-' }}</b>
                </div>
            </div>

            <div *ngIf="canEditField('categories')" class="dropdown-item d-flex justify-content-between" (click)="runAction('categories', elementData.categories)">
                <div>
                    <i class="fa-regular fa-list"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.categories.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getCategoriesLabel(elementData.categories) || '-' }}</b>
                </div>
            </div>

            <div *ngIf="canEditField('system_tags')" class="dropdown-item d-flex justify-content-between" (click)="runAction('system_tags', elementData.system_tags)">
                <div>
                    <i class="fa-regular fa-hashtag-lock"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.system_tags.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getSystemTagsLabel(elementData.system_tags) || '-' }}</b>
                </div>
            </div>

            <div *ngIf="canEditField('tags')" class="dropdown-item d-flex justify-content-between" (click)="runAction('tags', elementData.tags)">
                <div>
                    <i class="fa-regular fa-hashtag"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.tags.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ elementData.tags || '-' }}</b>
                </div>
            </div>

            <div *ngIf="canEditField('priority')" class="dropdown-item d-flex justify-content-between" (click)="runAction('priority', elementData.priority)">
                <div>
                    <i class="fa-regular fa-chevrons-up"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.priority.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getPriorityLabel(elementData.priority) || '-' }}</b>
                </div>
            </div>

            <div *ngIf="actions.user" class="dropdown-item d-flex justify-content-between" (click)="runAction('user', elementData.user_id)">
                <div>
                    <i class="fa-regular fa-user"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.user_id.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getUserLabel(elementData.user_id, elementData.related_user) || '-' }}</b>
                </div> 
            </div>

            <div *ngIf="actions.group" class="dropdown-item d-flex justify-content-between" (click)="runAction('group', elementData.group_id)">
                <div>
                    <i class="fa-regular fa-users"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.group_id.label' | locTranslate  }}</span>
                </div>
                <div>
                    <b class="ps-3">{{ getGroupLabel(elementData.group_id) || '-' }}</b>
                </div>
            </div>

            <div *ngIf="canActionRealm()" class="dropdown-item" (click)="runAction('realm', elementData.realm_id)">
                <div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <i class="fa-regular fa-castle"></i><span>{{ 'RESOURCES.' + getCleanResourceId() + '.fields.realm_id.label' | locTranslate  }}</span>
                        </div>
                        <div>
                            <b class="ps-3">{{ getRealmLabel(elementData.realm_id) || '-' }}</b>
                        </div>
                    </div>
                    
                    <div *ngIf="hasChangeRealmWarning()" class="mt-1">
                        <small class="alert alert-warning m-0 px-2 py-1 text-wrap">{{ 'pg-resource-element-menu.warning-change-realm' | locTranslate }}</small>
                    </div>  
                </div> 
            </div>

            <ng-container *ngIf="canEditField('template')">
                <div class="dropdown-divider"></div>
                
                <div class="dropdown-item" (click)="runAction('template')">
                    <i class="fa-regular fa-table"></i><span>{{ getActionLabel('template') | locTranslate }}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
