import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EFItineraryContent, EFPOI, EFProduct, EFSupplier } from '../../../models/experience.model';
import { PGShowcaseElement } from '../../../pg-ui-elements/pg-showcase/pg-showcase.component';
import { EnvironmentService } from '../../../services/environment.service';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';
import { TimetableData, TimetableDataDays, TimetableDataHour, TimetableDataList } from '../../../models/timetable.model';
import { EFDataService } from '../../../services/ef-data.service';

@Component({
  selector: 'app-ef-product',
  templateUrl: './ef-product.component.html',
  styleUrls: ['./ef-product.component.scss']
})
export class EfProductComponent implements OnInit {

    @Input() productData:EFProduct;
    @Input() supplierData:EFSupplier;
    @Input() POIData:EFPOI;
    @Input() appMode:'web'|'kiosk'|'hybrid'|'iframe'|'iframekiosk';
    @Input() oneColumn:boolean;
    @Input() hasBack:boolean;

    @Output() showProduct = new EventEmitter<string>()
    @Output() goBack = new EventEmitter<void>()

    constructor(protected localizationService:LocalizationService, protected environmentService:EnvironmentService, protected mapStylesService:MapStylesService, protected ngbModal:NgbModal, protected efDataService:EFDataService) { }

    CheckoutURL = null;

    useContactsQR = false;

    showcaseElements:Array<PGShowcaseElement> = null;

    isLoadingParent = false;
    parentProductData:EFProduct = null;

    isLoadingChildren = false;
    childrenProductData:Array<EFProduct> = null;

    ngOnInit(): void {
        this.CheckoutURL = this.environmentService.environment.CheckoutURL;

        if(this.appMode == 'kiosk' || this.appMode == 'iframekiosk') {
            this.useContactsQR = true;
        }

        if(this.productData.store_showcase != null) {

            this.showcaseElements = [];
            for(let _element of (this.productData.store_showcase)) {
                
                let _title = null;
                try {
                    _title = JSON.parse(_element.showcase_title)[this.localizationService.currentLanguage]
                }
                catch(ex) {}

                let _description = null;
                try {
                    _description = JSON.parse(_element.showcase_description)[this.localizationService.currentLanguage]
                }
                catch(ex) {}

                if(_title != null) {
                    this.showcaseElements.push({
                        title: _title,
                        image: _element.showcase_image,
                        description: _description,
                    })
                }
            }
        }

        if(this.productData.hasParent()) {
            this.isLoadingParent = true;

            this.efDataService.getResourceProduct(this.productData.resource, this.productData.parent).subscribe((data) => {
                this.isLoadingParent = false;
                this.parentProductData = data;
            }, () => {
                this.isLoadingParent = false;
            })
        }

        if(this.productData.canHaveChildren()) {
            this.isLoadingChildren = true;

            // TODO: in questo null nei gruppi, serve che mi porto dietro il filtro?
            this.efDataService.listResourceProducts(this.productData.resource, null, this.productData.id).subscribe((data) => {
                this.isLoadingChildren = false;
                this.childrenProductData = data;
            }, () => {
                this.isLoadingChildren = false;
            })
        }
    }

    private _mapOptionsMaps: {
        [resource:string]: {
            [prop:string]: string|((val:string) => string)
        }
    } = {
        'host': {
            'category': 'type'
        },
        'eatery': {
            'category': 'type'
        },
        'municipium:poi': {
            'category': (val:string) => {
                if(val != null) {
                    let _cSplit = val.split(',');

                    let _retVal = '';
                    for(let _cCategory of _cSplit) {
                        if(_retVal != '') _retVal += ', '

                        _retVal += this.localizationService.translate('OPTIONMAPS.ExperienceSupplier.category.' + _cCategory)
                    }

                    return _retVal;
                }
            }
        }
    }

    getOptionMappedDisplay(val:string, resource:string, prop:string) {
        if(val != null) {
            let _normalizedResource = resource.charAt(0).toUpperCase() + resource.substring(1);

            if(this._mapOptionsMaps[resource] != null && this._mapOptionsMaps[resource][prop] != null) {
                let _cMap = this._mapOptionsMaps[resource][prop];
                if(typeof _cMap == 'string') {
                    prop = _cMap;
                    return this.localizationService.translate('OPTIONMAPS.' + _normalizedResource + '.' + prop + '.' + val)
                }
                else {
                    return _cMap(val)
                }
            }
            else return this.localizationService.translate('OPTIONMAPS.' + _normalizedResource + '.' + prop + '.' + val);
        }
    }
    
    getOptionMappedArrayStringDisplay(list:any, resource:string, prop:string) {
        if(list == null || list == '' || list == '[]') return null;
        else {
            let _cList:Array<string> = null;
            
            if(typeof list == 'string') _cList = JSON.parse(list);
            else _cList = list;

            let _retVal = '';

            for(let _cVal of _cList) {
                if(_retVal != '') _retVal += ', ';

                _retVal += this.getOptionMappedDisplay(_cVal, resource, prop)
            }

            return _retVal;
        }
    }

    getTagsDisplay(list:any) {
        if(list == null || list == '' || list == '[]') return null;
        else {
            let _cList:Array<string> = null;
        
            if(typeof list == 'string') _cList = JSON.parse(list);
            else _cList = list;
    
            let _retVal = '';
    
            for(let _cElement of _cList) {
                if(_retVal != '') _retVal += ', ';
                _retVal += _cElement.replace(/\s/g, '_');
            }
    
            return _retVal;
        }
    }

    @ViewChild('modalAvailabilityDetails') modalAvailabilityDetails:ElementRef;

    modalAvailabilityDetailsTarget:Array<TimetableData> = null;

    private _modalAvailabilityDetailsRef:NgbModalRef = null;

    isAvailabilityValid(availability:TimetableData) {
        for(let _day of availability.days) {
            if(_day.year == null) return true;
            else {
                let _dateEnd = new Date(_day.year, _day.end.month, _day.end.day);
                if(_dateEnd.getTime() > Date.now()) return true;
            }
        }
    }

    hasAvailabilityDetails(availability:TimetableDataList) {
        if(this.productData.getAvailabilityType() != 'timetable-days') {
            for(let _item of availability.list) {
                if(!_item.unavailable && _item.hours.length > 0) return true;
            }
        }
    }

    openAvailabilityDetails(availability:TimetableDataList) {
        this.modalAvailabilityDetailsTarget = availability.toData();
        this._modalAvailabilityDetailsRef = this.ngbModal.open(this.modalAvailabilityDetails)
    }

    isAvailabilityDayAllYear(day:TimetableDataDays) {
        return day.begin.month == 1 && day.begin.day == 1 && day.end.month == 12 && day.end.day == 31;
    }

    getAvailabilityDayDates(day:TimetableDataDays) {
        let _beginYear = day.year;
        let _endYear = day.year;

        if(_beginYear != null) {
            if(day.end.month < day.begin.month || (day.end.month == day.begin.month && day.end.day < day.begin.day)) {
                _endYear++;
            }
        }

        return this.localizationService.translate('pg-timetable-editor.from-day') + ' ' + 
            this.localizationService.format.date({ year: _beginYear, month: day.begin.month, day: day.begin.day }) + ' ' +
            this.localizationService.translate('pg-timetable-editor.to-day') + ' ' + 
            this.localizationService.format.date({ year: _endYear, month: day.end.month, day: day.end.day });
    }

    closeAvailabilityDetails() {
        if(this._modalAvailabilityDetailsRef != null) this._modalAvailabilityDetailsRef.close();
    }

    getDayName(index:number) {
        index = (this.localizationService.data.weekStart + index) % 7
        return this.localizationService.data.dayNames[index].substring(0, 3)
    }

    getDayStatus(hour:TimetableDataHour, index:number) {
        index = (this.localizationService.data.weekStart + index) % 7

        return hour.days[index];
    }

    formatProductLocation(noAddress?:boolean) {
        let _retVal = '';
        
        if(this.productData.city != null) _retVal += this.productData.city;
        if(this.productData.province != null) {
            if(_retVal != '') _retVal += ' '
            _retVal += '(' + this.productData.province + ')';
        }

        if(!noAddress) {
            if(this.productData.address != null) {
                if(_retVal != '') _retVal += '<br/>'
                _retVal += this.productData.address;
            }
        }

        return _retVal
    }

    getTypeColor(type:string) {
        if(this.mapStylesService.markerStyles[type] != null) {
            return this.mapStylesService.markerStyles[type].icon.fillColor;
        }
    }
    
    getTypeIcon(type:string) {
        if(this.mapStylesService.markerStyles[type] != null) {
            return this.mapStylesService.markerStyles[type].label.text;
        }
    }

    @ViewChild('modalExternalLink') modalExternalLink:ElementRef;

    modalExternalLinkTarget:string = null;

    private _modalExternalLinkRef:NgbModalRef = null;

    openExternalLink(url:string) {
        this.modalExternalLinkTarget = url;
        this._modalExternalLinkRef = this.ngbModal.open(this.modalExternalLink)
    }

    closeExternalLink() {
        if(this._modalExternalLinkRef != null) this._modalExternalLinkRef.close();
    }

    getLanguagesDisplay(languages:Array<string>) {
        if(languages != null && languages.length > 0) {
            let _retVal = '';

            for(let _language of languages) {
                if(_retVal != '') _retVal += ', '
    
                _retVal += this.localizationService.languageLabels[_language]
            }
    
            return _retVal;
        }
    }

    getCostStartingAtDisplay(range:number|Array<number>) {
        if(range != null) {
            if(typeof range != 'number') {
                if(range[0] == 0) return this.localizationService.translate('experience-finder.ef-product.cost-free')
                else return range[0].toString() + ' &euro;'
            }
        }
    }

    getCostRangeDisplay(range:number|Array<number>, starting?:boolean) {
        if(range != null) {
            if(typeof range == 'number') {
                let _retVal = '<span>'

                for(let i = 0; i < 5; i++) {
                    _retVal += '<i class="fa-regular fa-euro-sign mr-2 ' + (i < range ? 'text-primary' : 'text-muted') + '"></i>'  
                }

                _retVal += '</span>'

                return _retVal;
            }
        }
    }

    onShowProduct(product:string) {
        this.showProduct.emit(product)
    }

    getProductMenuUrl() {
        if(this.productData.menu_file != null && this.productData.menu_file != '') {
            return this.environmentService.environment.APIUrl + '/public/' + this.productData.resource + '/' + this.productData.id.replace(this.productData.resource + '_', '') + '/menu';
        }
    }

    getProductMenuUrl2() {
        if(this.productData.menu_file_2 != null && this.productData.menu_file_2 != '') {
            return this.environmentService.environment.APIUrl + '/public/' + this.productData.resource + '/' + this.productData.id.replace(this.productData.resource + '_', '') + '/menu2';
        }
    }

    hasProductMenuUrl() {
        return this.getProductMenuUrl() || this.getProductMenuUrl2();
    }

    getTranslatedValue(object:any, field:string) {
        if(object.translations != null) {
            for(let _translation of object.translations) {
                if(_translation.language == this.localizationService.currentLanguage) {
                    if(_translation[field] != null && _translation[field] != '') return _translation[field];
                    else break;
                }
            }
        }

        return object[field];
    }

    getCheckoutURL() {
        if(this.productData.booking != null) {
            return this.productData.booking;
        }
        else if(this.efDataService.isResourceProduct(this.productData) && this.environmentService.environment.CheckoutURL != null) {
            return this.environmentService.environment.CheckoutURL + '/book-' + this.productData.id.split('_')[0] + '/' + this.productData.id.split('_')[1];
        }
    }

    getPaymentMethodDisplay() {
        if(this.productData.payment != null) {
            let _retVal = '';

            for(let _payment of this.productData.payment) {
                if(_retVal != '') _retVal += ', ';
                _retVal += this.localizationService.translate('OPTIONMAPS.Experience.payment.' + _payment)
            }

            return this.localizationService.translate('experience-finder.ef-product.payment') + ' ' + _retVal;
        }
    }

    @ViewChild('modalBookingQR') modalBookingQR:ElementRef;

    modalBookingQRTarget:string = null;

    private _modalBookingQRRef:NgbModalRef = null;

    showBookingQR(url:string) {
        this.modalBookingQRTarget = url;
        this._modalBookingQRRef = this.ngbModal.open(this.modalBookingQR)
    }

    hideBookingQR() {
        if(this._modalBookingQRRef != null) this._modalBookingQRRef.close();
    }

    getServiceTypeLabel(service:string) {
        let _normalizedResource = this.productData.resource.charAt(0).toUpperCase() + this.productData.resource.substring(1);

        return this.localizationService.translate('RESOURCES.' + _normalizedResource + '.fields.' + service + '.label')
    }

    getServiceItemDisplay(item:string, service:string) {
        return this.getOptionMappedDisplay(item, this.productData.resource, service.replace(/^common_/, ''))
    }

    getServiceListDisplay(list:Array<string>, service:string) {
        let _retVal = '';

        for(let _cVal of list) {
            if(_retVal != '') _retVal += ', ';

            _retVal += this.getServiceItemDisplay(_cVal, service)
        }

        return _retVal;
    }

    getAdditionalDescriptionLabel(description:string) {
        let _normalizedResource = this.productData.resource.charAt(0).toUpperCase() + this.productData.resource.substring(1);

        return this.localizationService.translate('RESOURCES.' + _normalizedResource + '.fields.' + description + '.label')
    }

    hasProductMap() {
        return this.productData.type == 'itinerary'
    }

    @ViewChild('modalShowStop') modalShowStop:ElementRef;

    modalShowStopData:EFItineraryContent = null;

    private _modalShowStopDataRef:NgbModalRef = null;

    onShowStop(stop:EFItineraryContent) {
        this.modalShowStopData = stop;
        this._modalShowStopDataRef = this.ngbModal.open(this.modalShowStop)
    }

    hideShowStop() {
        this._modalShowStopDataRef.close()
    }
}
