import { Component, ElementRef, ViewChild } from '@angular/core';
import { PgResourceViewTableConfig } from './config/pg-resource-view-table-config.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PGViewResourceInfiniteComponent } from '../resource-view-common';
import { ConfigResourceSemantics, ConfigResourceTypes, ConfigResourceView } from '../../models/config.resources.model';
import { CachedDataSelects } from '../../models/cached-data';
import { DataService, GetResourceDataOptions } from '../../services/data.service';
import { ConfigService } from '../../services/config.service';
import { ConfigSemanticsRelations, SemanticsService } from '../../services/semantics.service';
import { EnvironmentService } from '../../services/environment.service';
import { LocalizationService } from '../../services/localization.service';
import { ConfigRelation } from '../../models/config.relations.model';
import { NotificationsService } from '../../services/notifications.service';

class ColumnStatus {
    label:string = null;

    roleview?:string = null;
    cluster?:string = null;

    field?:string = null;
    type?:ConfigResourceTypes = null;

    sum?:number = null;
}

@Component({
  selector: 'app-pg-resource-view-table',
  templateUrl: './pg-resource-view-table.component.html',
  styleUrls: ['./pg-resource-view-table.component.scss']
})

export class PgResourceViewTableComponent extends PGViewResourceInfiniteComponent {
    
    viewConfig:ConfigResourceView<PgResourceViewTableConfig>;
    
    @ViewChild('tableScrollable', { static: true }) tableScrollable:ElementRef;

    @ViewChild('tableHeaderOuter', { static: true }) tableHeaderOuter:ElementRef;
    @ViewChild('tableHeaderInner', { static: true }) tableHeaderInner:ElementRef;

    cachedSelects:CachedDataSelects = null;

    columnStatus:Array<ColumnStatus> = null;

    constructor(dataService: DataService, configService: ConfigService, semanticsService:SemanticsService, router: Router, route:ActivatedRoute, environmentService:EnvironmentService, modalService:NgbModal, localizationService:LocalizationService, notificationsService:NotificationsService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService);
    }

    isInitialized = false;
    
    private _alignHeaderScrollFunction = null;
    private _alignHeaderWidthFunction = null;

    private _lastAlignedScroll = null;

    private _getRowStatus:Function = null;

    ngOnInit() {
        super.ngOnInit();
        
        let _that = this;

        let _lastCopiedHeader = null;

        this._alignHeaderWidthFunction = function() {
            if(_that.tableHeaderInner.nativeElement.children[0] != null) {
                if(_lastCopiedHeader != _that.tableHeaderInner.nativeElement.children[0].innerHTML) {
                    _lastCopiedHeader = _that.tableHeaderInner.nativeElement.children[0].innerHTML;
                    _that.tableHeaderOuter.nativeElement.children[0].innerHTML = _lastCopiedHeader;
                }

                for(let i = 0; i < _that.tableHeaderOuter.nativeElement.children[0].children.length; i++) {
                    let _cHeaderOuterCell = _that.tableHeaderOuter.nativeElement.children[0].children[i];
                    let _cHeaderCell = _that.tableHeaderInner.nativeElement.children[0].children[i];
    
                    if(_cHeaderOuterCell.offsetWidth != _cHeaderCell.offsetWidth) {
                        _cHeaderOuterCell.style.minWidth = _cHeaderCell.offsetWidth + 'px';
                        _cHeaderOuterCell.style.maxWidth = _cHeaderCell.offsetWidth + 'px';
                    }
                }
            }
        }

        this._alignHeaderScrollFunction = function() {
            if(_that.tableScrollable.nativeElement.scrollLeft != _that._lastAlignedScroll) {
                _that._lastAlignedScroll = _that.tableScrollable.nativeElement.scrollLeft;
                _that.tableHeaderOuter.nativeElement.children[0].style.transform = 'translate(-' + _that._lastAlignedScroll + 'px, 0)';
            }
        }
        
        this.tableScrollable.nativeElement.addEventListener('scroll', this._alignHeaderScrollFunction);
    }

    ngAfterViewChecked() {
        super.ngAfterViewChecked();

        this._alignHeaderWidthFunction();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.tableScrollable.nativeElement.removeEventListener('scroll', this._alignHeaderScrollFunction);
    }

    isFieldColumn = PgResourceViewTableConfig.isFieldColumn;
    isRoleviewColumn = PgResourceViewTableConfig.isRoleviewColumn;
    isClusterColumn = PgResourceViewTableConfig.isClusterColumn;

    fieldTypes: {
        [name:string]: ConfigResourceTypes
    } = null;

    private _resourceDefinedRoles:ConfigResourceSemantics = null; // le colonne roleview possono usare il nome di un role già definito (title, abstract, description...) oppure definirne uno custom

    private _translationRelation:ConfigRelation = null;

    ngOnChanges() {
        super.ngOnChanges();

        this._resourceDefinedRoles = this.configService.getResourceGeneralConfig(this.resourceId).roles;

        this._getRowStatus = null;

        if(this.viewConfig.config.getRowStatus != null) {
            this._getRowStatus = eval('(function($row) {' + this.viewConfig.config.getRowStatus + '})');
        }

        this.columnStatus = [];

        // TODO: rendere più elegante la creazione di columnStatus
        // generale una lista di campi visibili per evitare di richiedere tutte le select se non è necessario

        this.fieldTypes = this.configService.getResourceFieldTypes(this.resourceId);

        let _usedRelations = new ConfigSemanticsRelations();
        let _allRelations = this.configService.getResourceRelations(this.resourceId);

        let _columnsByField = {};

        this._translationRelation = null;

        for(let _cColumn of this.viewConfig.config.columns) {
            let _cColStatus = new ColumnStatus();
            
            if(this.isFieldColumn(_cColumn)) {
                // colonne traduzione
                if(/@/.test(_cColumn.field)) {
                    let _relatedField = _cColumn.field.split('@')[0];
                    let _relatedResource = _cColumn.field.split('@')[1];

                    this._translationRelation = this.configService.getResourceTranslatedByRelation(this.resourceId);

                    if(this._translationRelation != null) {
                        _usedRelations.xToN.push(this._translationRelation);
                    }

                    let _relatedResourceDataName = _relatedResource.replace(/([A-Z])/g, '_$1');
                    _relatedResourceDataName = 'related' + _relatedResourceDataName.toLowerCase();

                    _cColStatus.roleview = '{{#' + _relatedResourceDataName + '}}{{' + _relatedField + '}}{{/' + _relatedResourceDataName + '}}';
                    _cColStatus.label = this.configService.getResourceFieldTypes(_relatedResource)[_relatedField].label;
                }
                else {
                    _cColStatus.field = _cColumn.field;
                    if(_cColumn.sum) _cColStatus.sum = 0;

                    _cColStatus.label = this.fieldTypes[_cColumn.field].label;
                    _cColStatus.type = this.fieldTypes[_cColumn.field];

                    _columnsByField[_cColumn.field] = _cColStatus;
                }
            }
            else if(this.isRoleviewColumn(_cColumn)) {
                _cColStatus.roleview = _cColumn.roleview;
                _cColStatus.label = _cColumn.name;

                if(this._resourceDefinedRoles[_cColStatus.roleview] != null) {
                    this.semanticsService.getSemanticUsedRelations(this.resourceId, this._resourceDefinedRoles[_cColStatus.roleview], _usedRelations);
                }
                else {
                    this.semanticsService.getSemanticUsedRelations(this.resourceId, _cColumn.roleview, _usedRelations);
                }
            }
            else if(this.isClusterColumn(_cColumn)) {
                let _clusterSplit = _cColumn.cluster.split(',');

                for(let _cClusterCol of _clusterSplit) {
                    _columnsByField[_cClusterCol] = _cColStatus;
                }

                _cColStatus.cluster = _cColumn.cluster;
                _cColStatus.label = _cColumn.name;
            }
            
            this.columnStatus.push(_cColStatus);
        }

        // l'aggiunta delle colonne da filtri/ordinamenti potrebbe essere decisamente più elegante di così

        if(this.filterStatus != null) {
            for(let _cFilter of this.filterStatus.filter) {
                if(!_columnsByField[_cFilter.field]) {
                    let _cColStatus = new ColumnStatus();

                    _cColStatus.field = _cFilter.field;

                    _cColStatus.label = this.fieldTypes[_cFilter.field].label;
                    _cColStatus.type = this.fieldTypes[_cFilter.field];

                    _columnsByField[_cFilter.field] = _cColStatus;

                    this.columnStatus.push(_cColStatus);
                }

                _columnsByField[_cFilter.field].filter = true;
            }

            for(let _cOrder of this.filterStatus.order) {
                if(!_columnsByField[_cOrder.field]) {
                    let _cColStatus = new ColumnStatus();

                    _cColStatus.field = _cOrder.field;

                    _cColStatus.label = this.fieldTypes[_cOrder.field].label;
                    _cColStatus.type = this.fieldTypes[_cOrder.field];

                    _columnsByField[_cOrder.field] = _cColStatus;

                    this.columnStatus.push(_cColStatus);
                }

                _columnsByField[_cOrder.field].order = _cOrder.direction;
            }
        }

        this.cachedSelects = new CachedDataSelects(this.dataService, this.configService, this.semanticsService, this.resourceId, this.fieldTypes);

        this.withRelated = this.configService.getWithListFromSemanticsRelations(_usedRelations);

        if(this.fieldTypes.user_id) this.withRelated.push('User');

        this.reset();
    }

    reset() {
        this.isInitialized = false;
        super.reset();
    }

    beforeGetData(options:GetResourceDataOptions) {
        options = super.beforeGetData(options);

        // TODO: reinserire filtro sui dati per lingua
        /*
        if(this._translationRelation != null) {
            if(options.filter == null) options.filter = [];

            options.filter.push({ 
                resource: this._translationRelation.modelB, 
                field: 'language', 
                operator: '==', 
                value: [this.localizationService.currentLanguage] 
            })
        }
        */

        return options;
    }
    
    onLoadData(data:Array<any>) {
        return new Observable<any>((observer) => {
            this.cachedSelects.fillSelects(data).then(() => {
                this.isInitialized = true;

                for(let _cCol of this.columnStatus) {
                    if(_cCol.field != null && _cCol.sum != null) {
                        for(let row of data) {
                            _cCol.sum += parseFloat(row[_cCol.field]);
                        }
                    }
                }

                if(this._translationRelation != null) {
                    let _relatedResourceDataName = this._translationRelation.modelB.replace(/([A-Z])/g, '_$1');
                    _relatedResourceDataName = 'related' + _relatedResourceDataName.toLowerCase();

                    for(let _cRow of data) {
                        let _cRelatedTranslation = _cRow[_relatedResourceDataName];
                        let _dataLanguage = this.localizationService.currentLanguage.replace('_', '-');

                        if(_cRelatedTranslation != null) {
                            for(let i = _cRelatedTranslation.length - 1; i >= 0; i--) {
                                if(_cRelatedTranslation[i].language != _dataLanguage) {
                                    _cRelatedTranslation.splice(i, 1);
                                }
                            }
                        }
                    }
                }

                observer.next(data);
                observer.unsubscribe();
            });
        })
    }

    hasFooter() {
        for(let _cCol of this.columnStatus) {
            if(_cCol.field != null && _cCol.sum != null) {
                return true;
            }
        }
    }

    getFieldType(field:string) {
        return this.fieldTypes[field];
    }

    // TODO: resettare la cache roleview in caso di modifica dati
    private _cachedRoleViews:{ [id:string]: { [role:string]: string } } = {}

    getRoleview(view:string, data:any) {
        
        let _cRole = view;

        if(this._resourceDefinedRoles[view] != null) {
            _cRole = this._resourceDefinedRoles[view];
        }

        if(this._cachedRoleViews[data.id] == null) this._cachedRoleViews[data.id] = {}

        if(this._cachedRoleViews[data.id][_cRole] == null) {
            for(let _cRelation of this.configService.getResourceRelations(this.resourceId)) {
                if(_cRelation.joinField != null && data[_cRelation.joinField] != null) {
                    data['related_' + _cRelation.joinField] = this.cachedSelects.cache[_cRelation.modelB][data[_cRelation.joinField]];
                }
            }
    
            this._cachedRoleViews[data.id][_cRole] = this.semanticsService.evaluateResourceSemanticTemplate(this.resourceId, _cRole, data);
        }

        return this._cachedRoleViews[data.id][_cRole];
    }

    getClusterColumns(view:string) {
        return view.split(',');
    }

    getRowClass(row:any) {
        let _rowStatus = null;
        if(this._getRowStatus != null) _rowStatus = this._getRowStatus(row);

        if(_rowStatus != null) {
            return 'bg-' + _rowStatus.toLowerCase();
        }
        else return '';
    }

    onRowClick(event, row:any) {

        let _cElement = event.target;
        let _isInLink = false;
        
        while(_cElement != null) {
            if(_cElement.tagName.toLowerCase() == 'a') {
                _isInLink = true;
                break;
            }
            else if(_cElement.classList.contains('col')) {
                break;
            }

            _cElement = _cElement.parentNode;
        }

        if(!_isInLink) {
            this.router.navigate([this.getBaseURL() + '/detail/' + this.resourceId + '/' + row.id], {relativeTo: this.route });
        }
    }

    hasFlags(row:any) {
        return row.group_id != null || row.related_user != null;
    }

    createNew() {
        this.configService.getResourceNewElement(this.resourceId, this.relatedResource, this.relatedElement).subscribe((newData) => {
            this.dataService.createDraftElement(this.resourceId, newData).subscribe(data => {
                this.router.navigate([this.getBaseURL() +'/form/' + this.resourceId + '/' + data.id], { relativeTo: this.route });
            });
        })
    }

    getGroupLabel(id:string) {
        for(let _group of this.configService.groups) {
            if(_group.id == id) return _group.label
        }
    }
}
