import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DataService, GetResourceDataOptions, ResourceMode, UserRealmData } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';
import { EnvironmentService } from '../../services/environment.service';
import { PgCopyClipboardOption } from '../../pg-ui-elements/pg-copy-clipboard/pg-copy-clipboard.component';

@Component({
  selector: 'app-pg-admin-realm-users',
  templateUrl: './pg-admin-realm-users.component.html',
  styleUrls: ['./pg-admin-realm-users.component.scss']
})
export class PgAdminRealmUsersComponent implements OnInit, OnChanges {

    @Input() realmId:string;
    @Input() resourceMode:ResourceMode;

    isLoading = false;
    isSavingUserRealm:{ [id:string]: boolean } = {};

    userRealmData:Array<UserRealmData> = null;

    idNameSemantic = '{{id}} - {{name}}'
    idLabelSemantic = '{{id}} - {{label}}'

    constructor(private dataService:DataService, private authService:AuthService, private environmentService:EnvironmentService, private localizationService:LocalizationService) { }

    ngOnInit(): void {
        if(this.userRealmData == null) {
            this.loadData()
        }
    }

    ngOnChanges() {
        this.loadData()
    }

    pageSize = 10;
    pageNum = 0;

    atEnd = false;

    realmName:string = null;

    inviteOptions:Array<PgCopyClipboardOption> = null;

    loadData() {
        this.realmName = null
        this.inviteOptions = null;

        if(this.resourceMode != 'admin') {
            this.realmId = this.authService.user.realm?.id;
        }

        this.realmName = this.environmentService.getRealmName(this.realmId, true);

        this.inviteOptions = [{
            value: this.environmentService.environment.FormsURL + '/realms/' + this.realmName,
            text: this.localizationService.translate('pg-admin-realm-users.invite-forms')
        }, {
            value: this.environmentService.environment.EntouranceURL + '/realms/' + this.realmName,
            text: this.localizationService.translate('pg-admin-realm-users.invite-entourance')
        }];

        this.atEnd = false;
        this.pageNum = 0;
        this.userRealmData = [];
        this.loadMoreData()
    }

    loadMoreData() {
        if(!this.isLoading) { // TODO: qua la richiesta è fatta su UserRealm, non posso fare una with group e role, questo vuol dire che per ogni user devo fare altre 2 query
            let _resourceOptions:GetResourceDataOptions = {
                with: ['User'],
                limit: this.pageSize,
                offset: this.pageNum * this.pageSize
            };
    
            _resourceOptions.filter = [{ field: 'realm_id', operator: '==', value: [ this.getRealmFilter() ] }]
    
            this.isLoading = true;
                
            this.dataService.getResourceData('UserRealm', _resourceOptions, null, null, this.resourceMode).subscribe((data) => {
                this.isLoading = false;

                this.pageNum++;
                this.atEnd = data.length < this.pageSize;
    
                for(let _item of data) {
                    this.userRealmData.push(_item)
                }
            })   
        }
    }

    setUserRealmStatus(id:string, status:string) {
        if(!this.isSavingUserRealm[id]) {
            this.isSavingUserRealm[id] = true;

            this.dataService.putElementData('UserRealm', id, { status: status }, 'admin').subscribe((data) => {
                delete this.isSavingUserRealm[id]

                for(let _userRealm of this.userRealmData) {
                    if(_userRealm.id == id) {
                        _userRealm.status = data.status;
                        break;
                    }
                }
            })
        }
    }

    addUser:string = null;

    doAddUser() {
        this.isLoading = true;
        this.dataService.postResourceData('UserRealm', { user_id: this.addUser, realm_id: this.getRealmFilter(), status: 'confirmed' }, this.resourceMode).subscribe((data) => {
            this.isLoading = false;

            this.loadData()
        })
    }

    getRealmFilter() {
        if(this.resourceMode == 'admin') return this.realmId;
        else return this.authService.user.realm?.id;
    }
}
