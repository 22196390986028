<div class="PGLocationPicker {{readonly ? '' : 'PGLocationPicker--NotReadonly'}}">
    <input [hidden]="showAdvanced" type="text" class="form-control form-control-sm PGLocationPicker-Search {{ readonly ? 'PGLocationPicker-Search--Readonly' : '' }}" #searchbox [placeholder]="placeholder || 'Cerca un luogo'"/>
    <div [hidden]="showAdvanced" class="PGLocationPicker-Map {{readonly ? 'PGLocationPicker-Map--ReadOnly' : ''}} GoogleMaps" #gmaps></div>
    <ng-container *ngIf="!showAdvanced">
        <div class="PGLocationPicker-Slaves">
            <i class="fa-regular fa-map-marker me-2 pt-2"></i>
            <ng-container *ngIf="hasSlaves() && !isLoadingAddress">
                <ng-container *ngFor="let key of ['address', 'zipcode', 'city', 'province', 'region', 'country']">
                    <span *ngIf="slaves[key]" class="PGLocationPicker-Slaves-Item">{{slaves[key]}}<span class="PGLocationPicker-Slaves-Item-Comma">, </span></span>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="showAdvanced">
        <div class="row">
            <div class="col input-group">
                <div class="input-group-text">
                    LAT
                </div>
                <input [readonly]="readonly" class="form-control" [ngModel]="getAdvancedValue(0)" (ngModelChange)="setAdvancedValue(0, $event)"/>
            </div>
            <div class="col input-group">
                <div class="input-group-text">
                    LON
                </div>
                <input [readonly]="readonly" class="form-control" [ngModel]="getAdvancedValue(1)" (ngModelChange)="setAdvancedValue(1, $event)"/>
            </div>
        </div>
    </div>
    <div *ngIf="allowAdvanced" class="PGControlsAdvanced">
        <button class="btn btn-sm btn-link" (click)="showAdvanced = !showAdvanced">
            <i *ngIf="!showAdvanced" class="fa-regular fa-code"></i>
            <i *ngIf="showAdvanced" class="fa-regular fa-circle"></i>
        </button>
    </div>
</div>