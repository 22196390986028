import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService, RealmData } from '../../services/environment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-realm-invite',
  templateUrl: './pg-realm-invite.component.html',
  styleUrls: ['./pg-realm-invite.component.scss']
})
export class PgRealmInviteComponent implements OnInit {

    constructor(private route:ActivatedRoute, private authService:AuthService, private environmentService:EnvironmentService, private modalService:NgbModal) { }

    realm:RealmData = null;

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.realm = this.environmentService.getRealm(params.realm)
        })
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    getRealmStatus() {
        for(let _realm of this.authService.user.availableRealms) {
            if(_realm.id == this.realm.id) return 'available'
        }

        for(let _realm of this.authService.user.unavailableRealms) {
            if(_realm.id == this.realm.id) return 'unavailable'
        }

        for(let _realm of this.authService.user.pendingRealms) {
            if(_realm.id == this.realm.id) return 'pending'
        }
    }

    isRequestingAddRealm = false;

    requestAddRealm() {
        this.isRequestingAddRealm = true;

        this.authService.requestAddRealm(this.realm.id).then((data) => {
            this.isRequestingAddRealm = false;
        })
    }

    hasContacts() {
        return Object.keys(this.realm.contacts).length > 0
    }
}
