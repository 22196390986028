import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PgSendMessageModalComponent } from './pg-send-message-modal/pg-send-message-modal.component';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgLoginHandlerComponent } from './pg-login-handler/pg-login-handler.component';
import { PgSlugEditorComponent } from './pg-slug-editor/pg-slug-editor.component';
import { PgHeaderComponent } from './pg-header/pg-header.component';
import { PgCodeCheckerComponent } from './pg-code-checker/pg-code-checker.component';
import { PgUserWidgetComponent } from './pg-user-widget/pg-user-widget.component';
import { PgWeatherComponent } from './pg-weather/pg-weather.component';
import { PgProfileEditorComponent } from './pg-profile-editor/pg-profile-editor.component';
import { PgUserMenuComponent } from './pg-user-menu/pg-user-menu.component';
import { PgCalendarAvailabilityComponent } from './pg-calendar/pg-calendar-availability.component';
import { PgCalendarResourceComponent } from './pg-calendar/pg-calendar-resource.component';
import { PgCalendarSelectComponent } from './pg-calendar/pg-calendar-select.component';
import { PgUserPickerComponent } from './pg-user-picker/pg-user-picker.component';
import { PgResourceAssociationComponent } from './pg-resource-association/pg-resource-association.component';
import { PgGroupPickerComponent } from './pg-group-picker/pg-group-picker.component';
import { PgGroupWidgetComponent } from './pg-group-widget/pg-group-widget.component';
import { PgAdminGroupsComponent } from './pg-admin-groups/pg-admin-groups.component';
import { PgAdminUsersComponent } from './pg-admin-users/pg-admin-users.component';
import { PgHelpModule } from '../pg-help/pg-help.module';
import { PgTasksViewComponent } from './pg-tasks-view/pg-tasks-view.component';
import { PgFooterComponent } from './pg-footer/pg-footer.component';
import { PgAvailabilitySelectComponent } from './pg-availability-select/pg-availability-select.component';
import { PgChatModule } from '../pg-chat/pg-chat.module';
import { PgPriorityPickerComponent } from './pg-priority-picker/pg-priority-picker.component';
import { PgCustomerCareSettingsComponent } from './pg-customer-care-settings/pg-customer-care-settings.component';
import { PgAdminRealmUsersComponent } from './pg-admin-realm-users/pg-admin-realm-users.component';
import { PgRealmPickerComponent } from './pg-realm-picker/pg-realm-picker.component';
import { PgRealmInviteComponent } from './pg-realm-invite/pg-realm-invite.component';
import { PgAcceptConditionsComponent } from './pg-accept-conditions/pg-accept-conditions.component';
import { PgResourceElementMenuComponent } from './pg-resource-element-menu/pg-resource-element-menu.component';
import { PgTagPickerComponent } from './pg-tag-picker/pg-tag-picker.component';
import { PgSelectPickerComponent } from './pg-select-picker/pg-select-picker.component';


@NgModule({
    declarations: [
        PgSendMessageModalComponent,
        PgLoginHandlerComponent,
        PgSlugEditorComponent,
        PgHeaderComponent,
        PgCodeCheckerComponent,
        PgUserWidgetComponent,
        PgWeatherComponent,
        PgProfileEditorComponent,
        PgUserMenuComponent,
        PgCalendarAvailabilityComponent,
        PgCalendarResourceComponent,
        PgCalendarSelectComponent,
        PgUserPickerComponent,
        PgGroupPickerComponent,
        PgResourceAssociationComponent,
        PgGroupWidgetComponent,
        PgAdminGroupsComponent,
        PgAdminUsersComponent,
        PgTasksViewComponent,
        PgFooterComponent,
        PgAvailabilitySelectComponent,
        PgPriorityPickerComponent,
        PgAdminRealmUsersComponent,
        PgCustomerCareSettingsComponent,
        PgRealmPickerComponent,
        PgRealmInviteComponent,
        PgAcceptConditionsComponent,
        PgResourceElementMenuComponent,
        PgTagPickerComponent,
        PgSelectPickerComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiControlsModule,
        PgUiElementsModule,
        PgHelpModule,
        PgChatModule
    ],
    exports: [
        PgSendMessageModalComponent,
        PgLoginHandlerComponent,
        PgSlugEditorComponent,
        PgHeaderComponent,
        PgCodeCheckerComponent,
        PgUserWidgetComponent,
        PgGroupWidgetComponent,
        PgWeatherComponent,
        PgUserMenuComponent,
        PgCalendarAvailabilityComponent,
        PgCalendarResourceComponent,
        PgCalendarSelectComponent,
        PgUserPickerComponent,
        PgGroupPickerComponent,
        PgResourceAssociationComponent,
        PgAdminGroupsComponent,
        PgAdminUsersComponent,
        PgTasksViewComponent,
        PgFooterComponent,
        PgAvailabilitySelectComponent,
        PgPriorityPickerComponent,
        PgAdminRealmUsersComponent,
        PgCustomerCareSettingsComponent,
        PgRealmPickerComponent,
        PgRealmInviteComponent,
        PgAcceptConditionsComponent,
        PgResourceElementMenuComponent,
        PgTagPickerComponent,
        PgSelectPickerComponent
    ]
})
export class PgUiMoleculesModule { }
