<div class="container py-3 PGCreateBooking">
    <app-pg-loading *ngIf="isLoading || isSavingDraft" [isLarge]="true" [isOverlay]="isSavingDraft"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="productData == null || supplierData == null" class="alert alert-danger">
            <h4 class="mb-3">
                <i class="fa-regular fa-exclamation-triangle me-3"></i>
                <span>{{ 'pg-create-booking.not-found-title' | locTranslate }}</span>
            </h4>
            <p class="mb-0">
                <ng-container *ngIf="productData == null">{{ 'pg-create-booking.product-not-found-text' | locTranslate }}</ng-container>
                <ng-container *ngIf="productData != null && supplierData == null">{{ 'pg-create-booking.supplier-not-found-text' | locTranslate }}</ng-container>
            </p>
        </div>
        <ng-container *ngIf="productData != null && supplierData != null">
            <h1>{{ 'pg-create-booking.title-' + productType | locTranslate }}</h1>
            <div *ngIf="warnNoLongerAvailable" class="alert alert-warning">
                <h4 class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle me-3"></i>
                    <span>{{ 'pg-create-booking.no-longer-available-title' | locTranslate }}</span>
                </h4>
                <p class="mb-0">
                    {{ 'pg-create-booking.no-longer-available-text' | locTranslate }}
                </p>
            </div>
            <div *ngIf="warnBookingClosed" class="alert alert-warning">
                <h4 class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle me-3"></i>
                    <span>{{ 'pg-create-booking.booking-closed-title' | locTranslate }}</span>
                </h4>
                <p class="mb-0">
                    {{ 'pg-create-booking.booking-closed-text' | locTranslate }}
                </p>
            </div>
            <app-pg-booking-product compact="never" [productData]="productData" [selectedLanguage]="state.selectedLanguage" 
            [selectedAvailability]="state.selectedAvailability" [selectedAccommodation]="state.selectedAccommodation" [selectedTickets]="state.selectedTickets" [surveyAnswers]="state.surveyAnswers"
            [userName]="state.selectedPaymentMethod == null ? null : editUserContact.name" 
            [userEmail]="state.selectedPaymentMethod == null ? null : (editUserContact.via == 'email' ? editUserContact.value : null)" 
            [userPhone]="state.selectedPaymentMethod == null ? null : (editUserContact.via == 'phone' ? editUserContact.value : editUserContact.phone)" 
            [brokerCode]="state.selectedPaymentMethod != null && editBrokerCode.enabled && editBrokerCode.status == 'valid' ? editBrokerCode.value : null" 
            [discountCode]="state.selectedPaymentMethod != null && editVoucherCode.enabled && editVoucherCode.status == 'valid' ? editVoucherCode.result.code : null" 
            [discountValue]="state.selectedPaymentMethod != null && editVoucherCode.enabled && editVoucherCode.status == 'valid' ? editVoucherCode.result.discount : null" 
            [paymentMethod]="state.selectedPaymentMethod"></app-pg-booking-product>
            <div *ngIf="!isPayable()" class="alert alert-warning my-3">
                <h4 class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle me-3"></i>
                    <span>{{ 'pg-create-booking.no-payment-method-title' | locTranslate }}</span>
                </h4>
                <p class="mb-3">
                    {{ 'pg-create-booking.no-payment-method-text' | locTranslate }}
                </p>
                <div>
                    <div *ngFor="let experience of onlinePaymentOnlyExperiences">
                        <a routerLink="/edit/experience/{{experience.id}}">{{ experience.id }} - {{ experience.title }}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="productType == 'host' && accommodationData.length == 0" class="alert alert-warning my-3">
                <h4 class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle me-3"></i>
                    <span>{{ 'pg-create-booking.no-valid-accommodation-title' | locTranslate }}</span>
                </h4>
                <p class="mb-3">
                    {{ 'pg-create-booking.no-valid-accommodation-text' | locTranslate }}
                </p>
            </div>
            <div *ngIf="!productData.bookable" class="alert alert-warning my-3">
                <h4 class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle me-3"></i>
                    <span>{{ 'pg-create-booking.not-bookable-title' | locTranslate }}</span>
                </h4>
                <p class="mb-3">
                    {{ 'pg-create-booking.not-bookable-text' | locTranslate }}
                </p>
            </div>
            <ng-container *ngIf="productData.bookable && isPayable() && (productType != 'host' || accommodationData.length > 0)">
                <div *ngIf="lastStep != null">
                    <button class="btn btn-sm btn-link p-0 mt-2" (click)="goPrevStep()">
                        <span>{{ 'pg-create-booking.button-edit-' + getLastStepTranslationId() | locTranslate }}</span>
                    </button>
                </div>
                <div class="row">
                    <div *ngIf="currentStep == 'language'" class="col-12 mt-3">
                        <label><b>{{ 'pg-create-booking.' + productType + '-language' | locTranslate }}</b></label>
                        <select class="form-control" [(ngModel)]="state.selectedLanguage" (ngModelChange)="goNextStep()">
                            <option *ngFor="let language of languageOptions" [value]="language.value">{{ language.text }}</option>
                        </select>
                    </div>
                    
                    <div *ngIf="currentStep == 'availability'" class="col-12">
                        <h3 *ngIf="productType != 'host'" class="mt-3">{{ 'pg-create-booking.choose-date' | locTranslate }}</h3>
                        <h3 *ngIf="productType == 'host'" class="mt-3">{{ 'pg-create-booking.choose-date-host' | locTranslate }}</h3>
                        <app-pg-availability-select [productType]="productType" [productId]="productId" [productAvailability]="availabilityData" [productCapacity]="productData.open_event ? null : productData.maximum_participants" [bookingDeadline]="productData.booking_deadline" (availabilitySelected)="onAvailabilitySelected($event)"  [dayMode]="getAvailabilityDayMode()"></app-pg-availability-select>
                    </div>
        
                    <div *ngIf="currentStep == 'accommodation'" class="col-12">
                        <h3 class="mt-3">{{ 'pg-create-booking.choose-accommodation' | locTranslate }}</h3>
                        
                        <div *ngFor="let accommodation of accommodationData">
                            <button class="btn btn-block border mb-2 py-2 text-start PGCreateBooking-AccommodationButton" (click)="setSelectedAccommodation(accommodation)">
                                <div class="PGCreateBooking-AccommodationButton-Background bg-primary"></div>
                                <app-pg-product-host-accommodation [accommodationData]="accommodation"></app-pg-product-host-accommodation>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="currentStep == 'tickets'" class="col-12">
                        <h3 class="mt-3">{{ 'pg-create-booking.choose-tickets' + (productType == 'host' ? '-host' : '') | locTranslate }}</h3>
                        <app-pg-tickets-select [priceOnRequest]="priceOnRequest" [ticketsData]="ticketsData" [availabilitySelected]="state.selectedAvailability" (ticketsSelected)="onTicketsSelected($event)" [single]="productType == 'host'"></app-pg-tickets-select>
                    </div>

                    <div *ngIf="currentStep == 'survey'" class="col-12">
                        <h3 class="mt-3">{{ 'pg-create-booking.survey' | locTranslate }}</h3>
                        <app-pg-survey-answers [surveyData]="surveyData" (surveyAnswered)="onSurveyAnswered($event)"></app-pg-survey-answers>
                    </div>
        
                    <div *ngIf="currentStep == 'payment-method'" class="col-12">
                        <div class="mt-3">
                            <label><b>{{ 'pg-create-booking.input-contact-name' | locTranslate }}</b></label>
                            <input type="text" class="form-control" [(ngModel)]="editUserContact.name"/>
                            <small *ngIf="editUserContact.name == null || editUserContact.name == ''" class="form-text text-danger">{{ 'pg-create-booking.invalid-contact-name' | locTranslate }}</small>
                        </div>
                        <div class="mt-3">
                            <label><b>{{ 'pg-create-booking.input-contact' + (productData.mandatory_email ? '-email' : '') | locTranslate }}</b></label>
                            <app-pg-recipient [disablePhone]="productData.mandatory_email" [(ngModel)]="editUserContact.value" (viaChange)="editUserContact.via = $event;"></app-pg-recipient>
                            <small *ngIf="editUserContact.via == 'phone'" class="form-text">{{ 'pg-create-booking.phone-default-prefix' | locTranslate }}</small>
                            <small *ngIf="editUserContact.via == null" class="form-text text-danger">{{ 'pg-create-booking.invalid-'  + (productData.mandatory_email ? 'contact-email' : 'contact') | locTranslate }}</small>
                        </div>
                        <div *ngIf="productData.mandatory_email || editUserContact.via == 'email'" class="mt-3">
                            <label><b>{{ 'pg-create-booking.input-contact-phone' | locTranslate }}</b></label>
                            <app-pg-phone [(ngModel)]="editUserContact.phone"></app-pg-phone>
                            <small class="form-text">{{ 'pg-create-booking.phone-default-prefix' | locTranslate }}</small>
                            <small *ngIf="!isEditUserContactPhoneValid()" class="form-text text-danger d-block">{{ 'pg-create-booking.invalid-contact-phone' | locTranslate }}</small>
                        </div>

                        <ng-container *ngIf="isBookingFree()">
                            <button [disabled]="editUserContact.via == null" class="btn btn-primary btn-block mb-1 mt-3" (click)="onPaymentMethodSelected('gratis')">
                                <i class="fa-regular fa-gift"></i>
                                <span>{{'OPTIONMAPS.' + getCapitalizedProductType() + '.payment.gratis' | locTranslate}}</span>
                            </button>
                        </ng-container>
        
                        <ng-container *ngIf="!isBookingFree()">
                            <h3 class="mt-3">{{ 'pg-create-booking.choose-payment-method' | locTranslate }}</h3>
        
                            <ng-container *ngIf="hasTickets()">
                                <ng-container *ngIf="hasVoucher()">
                                    <div class="d-flex mb-3">
                                        <app-pg-switch [small]="true" [ngModel]="editVoucherCode.enabled" (ngModelChange)="setVoucherCodeEnabled($event)"></app-pg-switch>
                                        <div class="ms-2">{{ 'pg-create-booking.input-discount' | locTranslate }}</div>
                                    </div>
                                    <div *ngIf="editVoucherCode.enabled" class="mb-3">
                                        <label><b>{{ 'pg-create-booking.input-discount-code' | locTranslate }}</b></label>
                                        <app-pg-code-checker [(value)]="editVoucherCode.value" [codeCheck]="checkVoucherCode" (statusChange)="editVoucherCode.status = $event" (resultChange)="editVoucherCode.result = $event" (errorChange)="editVoucherCode.error = $event"></app-pg-code-checker>
                
                                        <div *ngIf="editVoucherCode.status == 'invalid'" class="form-text text-danger mb-3">
                                            {{ 'pg-create-booking.invalid-discount-code' + (editVoucherCode.error != null ? '-' + editVoucherCode.error : '') | locTranslate }}
                                        </div>
                                        <div *ngIf="editVoucherCode.status == 'valid'" class="mb-3">
                                            <div class="form-text text-success mb-3">{{ 'pg-create-booking.valid-discount-code' | locTranslate }}</div>
                                            <div>
                                                <span>{{ 'pg-create-booking.discount-value' | locTranslate }}</span>
                                                <b class="float-end">{{editVoucherCode.result.discount}} %</b>          
                                            </div>
                                            <div>
                                                <span>{{ 'pg-create-booking.discount-price' | locTranslate }}</span>
                                                <b class="float-end">{{getDiscountPrice(editVoucherCode.result.discount)}} &euro;</b>          
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="hasBroker()">
                                    <div class="d-flex mb-3">
                                        <app-pg-switch [small]="true" [ngModel]="editBrokerCode.enabled" (ngModelChange)="setBrokerCodeEnabled($event)"></app-pg-switch>
                                        <div class="ms-2">{{ 'pg-create-booking.input-broker' | locTranslate }}</div>
                                    </div>
                                    <div *ngIf="editBrokerCode.enabled">
                                        <div class="form-text mb-3" [innerHTML]="'pg-create-booking.input-broker-code-text' | locTranslate:{ url: brokerRegisterURL }"></div>
                                        <label><b>{{ 'pg-create-booking.input-broker-code' | locTranslate }}</b></label>
                                        <app-pg-code-checker [(value)]="editBrokerCode.value" [codeCheck]="checkBrokerCode" (statusChange)="editBrokerCode.status = $event" (resultChange)="editBrokerCode.result = $event"></app-pg-code-checker>
                                        <div class="d-flex my-2">
                                            <app-pg-switch [small]="true" [(ngModel)]="localStorageEnabled" (ngModelChange)="onLocalStorageEnabledChange()"></app-pg-switch>
                                            <div class="ms-2">{{ 'pg-create-booking.input-broker-remember-code' | locTranslate }}</div>
                                        </div>
                
                                        <div *ngIf="editBrokerCode.status == 'invalid'" class="form-text text-danger mb-3">{{ 'pg-create-booking.invalid-broker-code' | locTranslate }}</div>
                                        <div *ngIf="editBrokerCode.status == 'valid'" class="mb-3">
                                            <div class="form-text text-success mb-3">{{ 'pg-create-booking.valid-broker-code' | locTranslate:{ name: editBrokerCode.result.name } }}</div>
                                            <div>
                                                <span>{{ 'pg-create-booking.broker-discount' | locTranslate }}</span>
                                                <b class="float-end">{{productData.broker_discount}} %</b>          
                                            </div>
                                            <div>
                                                <span>{{ 'pg-create-booking.broker-quota' | locTranslate }}</span>
                                                <b class="float-end">{{getBrokerQuota()}} &euro;</b>          
                                            </div>
                                        </div>
                                        <button [disabled]="!isEditUserContactValid() || editBrokerCode.status != 'valid'" class="btn btn-primary btn-block mb-1" (click)="onPaymentMethodSelected('broker')">
                                            <span>{{ 'pg-create-booking.broker-confirm' | locTranslate}}</span>
                                        </button>
                                    </div>
                                </ng-container>
                            </ng-container>
                            
                            <ng-container *ngIf="!hasBroker() || !editBrokerCode.enabled">
                                <ng-container *ngIf="priceOnRequest">
                                    <button  [disabled]="!isEditUserContactValid()" class="btn btn-primary btn-block mb-1" (click)="onPaymentMethodSelected('price_on_request')">
                                        <i class="fa-regular fa-phone"></i>
                                        <span>{{'pg-create-booking.payment-price_on_request' | locTranslate}}</span>
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="!priceOnRequest">
                                    <button *ngFor="let paymentMethod of paymentMethodOptions" [disabled]="!isEditUserContactValid()" class="btn btn-primary btn-block mb-1" (click)="onPaymentMethodSelected(paymentMethod.value)">
                                        <i class="{{paymentMethod.icon}}"></i>
                                        <span>{{paymentMethod.text | locTranslate}}</span>
                                    </button>
                                </ng-container>
                            </ng-container>
                        </ng-container>
        
                    </div>

                    <ng-container *ngIf="currentStep == 'confirm'">
                        <div class="col-12 mt-3">
                            <label><b>{{ 'pg-create-booking.additional-informations' | locTranslate }}</b></label>
                            <textarea class="form-control" [(ngModel)]="state.additionalInformations" placeholder="{{ 'pg-create-booking.additional-informations-placeholder' | locTranslate }}"></textarea>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-primary btn-lg btn-block mt-3" [disabled]="!canConfirmBooking()" (click)="confirmBooking()">{{ 'pg-create-booking.button-confirm' | locTranslate }}</button>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<app-pg-login-handler *ngIf="mustLoginToProceed && !isFullyLoggedIn()" customMessage="{{ 'pg-create-booking.login-message' | locTranslate:{ disableSMS: disableSMS } }}" otpMode="true" codeMode="true"></app-pg-login-handler>