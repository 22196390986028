<div class="PGResourcePicker">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <span *ngIf="customContent" (click)="resourcePick()">
            <ng-content></ng-content>
        </span> 
        <ng-container *ngIf="!customContent">
            <div class="PGResourcePicker-Selection">
                <ng-container *ngFor="let selection of selectionData">
                    <ng-container *ngIf="selection != null">
                        <app-pg-file-preview *ngIf="viewType == 'Files'" [fileData]="selection" [viewMode]="fileType"></app-pg-file-preview>
                        <app-pg-semantic-view-simplecard *ngIf="viewType != 'Files'" [resourceId]="resourceId" [elementData]="selection"></app-pg-semantic-view-simplecard>
                    </ng-container>
                </ng-container>
            </div>
            <button *ngIf="selectionData.length == 0" class="btn btn-primary PGResourcePicker-Add" (click)="resourcePick()">
                <i class="fa-regular fa-chevron-right"></i><span>{{ 'pg-resource-picker.select-resource' | locTranslate:{ resource: resourceName} }}</span>
            </button>
            <button *ngIf="selectionData.length > 0" class="btn btn-sm btn-primary PGResourcePicker-Edit" (click)="resourcePick()">
                <i class="fa-regular fa-edit"></i>
            </button>
        </ng-container>
    </ng-container>
</div>

<ng-template #modalPicker>
    <div class="modal-header">
        <div class="input-group">
            <div class="input-group-text">
                <i class="fa-regular fa-search"></i>
            </div>
            <input type="text" class="form-control" [(ngModel)]="filterStatus.search" placeholder="{{ 'pg-resource-picker.search' | locTranslate }}" (keypress)="onSearchKeyUp($event)"/>
        </div>
        <button type="button" class="modal-close btn btn-link" (click)="dismissModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body p-0">
        <app-pg-resource-embed [resourceId]="resourceId" [relatedResource]="relatedResource" [relatedElement]="relatedElement" [filterStatus]="filterStatus" [selectionMode]="selectionMode || 'single'" [selectionField]="selectionField" [selection]="selection" (selectionChange)="onSelectionChange($event)"></app-pg-resource-embed>
    </div>
</ng-template>
