import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pg-loading',
  templateUrl: './pg-loading.component.html',
  styleUrls: ['./pg-loading.component.scss']
})

export class PgLoadingComponent implements OnInit {
    @Input() progressRatio:number = null;
    @Input() progressCurrent:number = null;
    @Input() progressTotal:number = null;
    @Input() hasError:boolean;
    @Input() isLarge:boolean;
    @Input() isSmall:boolean;
    @Input() isOverlay:boolean;
    @Input() isFullscreen:boolean;

    hasProgressBar() {
        return this.progressRatio != null || (this.progressCurrent != null && this.progressTotal != null && this.progressTotal != 0)
    }

    getProgressPercent() {
        if(this.progressRatio != null) {
            return Math.round(this.progressRatio * 100);
        }
        else if(this.progressCurrent != null && this.progressTotal != null && this.progressTotal != 0) {
            return Math.round(this.progressCurrent / this.progressTotal * 100);
        }
    }

    constructor() { }

    ngOnInit() {
    }
}
