<div class="FormsItinerary">
    <app-pg-loading *ngIf="isLoading || isSaving" [isOverlay]="isSaving"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div *ngIf="viewOnly" [hidden]="createModalData != null">
            <app-pg-detail [formLayout]="formLayout" [hideEmpty]="true" [currentLanguage]="true"></app-pg-detail>
            <div class="FormsItinerary-View">
                <div>
                    <app-ef-itinerary-map *ngIf="isFullyLoaded" [itineraryData]="itineraryData"></app-ef-itinerary-map>
                </div>
                <div class="ps-3">
                    <h4 class="mb-3">{{ 'forms-itinerary.itinerary-stops' | locTranslate }}</h4>
                    <div *ngFor="let item of itineraryData.collection;" class="FormsItinerary-Item mb-3">
                        <div class="FormsItinerary-Item-Content">
                            <app-ef-itinerary-stop-card [disabled]="true" [stopData]="item" (showProduct)="onShowProduct($event)"></app-ef-itinerary-stop-card>
                        </div>
                    </div>

                    <ng-container *ngIf="itineraryData.services?.length">
                        <h4 class="mb-3">{{ 'forms-itinerary.itinerary-services' | locTranslate }}</h4>
                        <div *ngFor="let item of itineraryData.services;" class="FormsItinerary-Item mb-3">
                            <div class="FormsItinerary-Item-Content">
                                <app-ef-itinerary-stop-card [disabled]="true" [stopData]="item" (showProduct)="onShowProduct($event)"></app-ef-itinerary-stop-card>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="!viewOnly" class="container-fluid" [hidden]="createModalData != null">
            <app-pg-form #formComponent [formLayout]="formLayout" [hasSubmit]="false" (valueChange)="onValueChange($event)">
                <div *ngIf="itineraryData != null">
                    <div class="my-3 pt-3 border-top d-flex justify-content-between align-items-center">
                        <h4 class="m-0">{{ 'forms-itinerary.itinerary-stops' | locTranslate }}</h4>
                    </div>
                    <div class="row">
                        <div *ngFor="let item of itineraryData.collection; let i = index" class="col-12 col-md-6 col-lg-4">
                            <div class="FormsItinerary-Item mb-3 card">
                                <div class="FormsItinerary-Item-Buttons">
                                    <button [disabled]="i <= 0" class="btn btn-link" (click)="moveItineraryItemPrev(item)">
                                        <i class="fa-regular fa-chevron-left"></i>
                                    </button>
                                    <button [disabled]="i >= itineraryData.collection.length - 1" class="btn btn-link" (click)="moveItineraryItemNext(item)">
                                        <i class="fa-regular fa-chevron-right"></i>
                                    </button>
                                    <div>
                                        <button class="btn btn-link text-primary" (click)="editItineraryStopItem(item)">
                                            <i class="fa-regular fa-edit"></i>
                                        </button>
                                        <app-pg-confirm-action (confirmed)="deleteItineraryStopItem(item)">
                                            <button class="btn btn-link text-danger">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </app-pg-confirm-action>
                                    </div>
                                </div>
                                <div class="FormsItinerary-Item-Content">
                                    <app-ef-itinerary-stop-card [disabled]="true" [stopData]="item" (showProduct)="onShowProduct($event)"></app-ef-itinerary-stop-card>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="FormsItinerary-Item mb-3">
                                <div class="card bg-white">
                                    <div class="card-body">
                                        <h5 class="mb-3">{{ 'forms-itinerary.itinerary-item-add' | locTranslate }}</h5>

                                        <div>
                                            <div class="form-group">
                                                <label><b>{{ 'forms-itinerary.itinerary-item-type' | locTranslate }}</b></label>
                                                <app-pg-select [options]="addStopResourceOptions" [(ngModel)]="addStopResourceSelected" (ngModelChange)="addStopElementSelected = null" [display]="{ oneLine: true }"></app-pg-select>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div class="form-group">
                                                <label>
                                                    <b *ngIf="addStopResourceSelected != 'External'">{{ 'forms-itinerary.itinerary-item' | locTranslate }}</b>
                                                    <b *ngIf="addStopResourceSelected == 'External'">{{ 'forms-itinerary.itinerary-url' | locTranslate }}</b>
                                                </label>
                                                <div>
                                                    <app-pg-select *ngIf="addStopResourceSelected != 'External'" [readonly]="addStopResourceSelected == null" [options]="addStopResourceSelected == null || addStopResourceSelected == 'External' ? [] : null" [resource]="addStopResourceSelected == 'External' ? null : addStopResourceSelected"  [(ngModel)]="addStopElementSelected" [display]="{ oneLine: true }" [resourceSemantic]="resourceSemantics[addStopResourceSelected]"></app-pg-select>
                                                    <input *ngIf="addStopResourceSelected == 'External'"type="text" class="form-control" [(ngModel)]="addStopElementSelected"/>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div class="text-end">
                                                <button [disabled]="addStopResourceSelected == null || addStopResourceSelected == 'External'" class="btn btn-primary" (click)="createStopResourceElement()">
                                                    <span>{{ 'forms-itinerary.itinerary-item-create' | locTranslate }}</span>
                                                </button>
                                                <button [disabled]="addStopResourceSelected == null || addStopElementSelected == null || addStopElementSelected == '' || isAddingStopResourceElement" class="btn btn-primary ms-2" (click)="addStopResourceElement()">
                                                    <span>{{ 'forms-itinerary.itinerary-item-add' | locTranslate }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-3 pt-3 border-top d-flex justify-content-between align-items-center">
                        <h4 class="m-0">{{ 'forms-itinerary.itinerary-services' | locTranslate }}</h4>
                    </div>
                    <div class="row">
                        <div *ngFor="let item of itineraryData.services; let i = index" class="col-12 col-md-6 col-lg-4">
                            <div class="FormsItinerary-Item mb-3 card">
                                <div class="FormsItinerary-Item-Buttons">
                                    <div>
                                        <app-pg-confirm-action (confirmed)="deleteItineraryServiceItem(item)">
                                            <button class="btn btn-link text-danger">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </app-pg-confirm-action>
                                    </div>
                                </div>
                                <div class="FormsItinerary-Item-Content">
                                    <app-ef-itinerary-stop-card [disabled]="true" [stopData]="item" (showProduct)="onShowProduct($event)"></app-ef-itinerary-stop-card>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <div class="FormsItinerary-Item mb-3">
                                <div class="card bg-white">
                                    <div class="card-body">
                                        <h5 class="mb-3">{{ 'forms-itinerary.itinerary-item-add' | locTranslate }}</h5>

                                        <div>
                                            <div class="form-group">
                                                <label><b>{{ 'forms-itinerary.itinerary-item-type' | locTranslate }}</b></label>
                                                <app-pg-select [options]="addServiceResourceOptions" [(ngModel)]="addServiceResourceSelected" (ngModelChange)="addServiceElementSelected = null" [display]="{ oneLine: true }"></app-pg-select>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div class="form-group">
                                                <label><b>{{ 'forms-itinerary.itinerary-item' | locTranslate }}</b></label>
                                                <div>
                                                    <app-pg-select [readonly]="addServiceResourceSelected == null" [options]="addServiceResourceSelected == null ? [] : null" [resource]="addServiceResourceSelected" [(ngModel)]="addServiceElementSelected" [display]="{ oneLine: true }" [resourceSemantic]="resourceSemantics[addServiceResourceSelected]"></app-pg-select>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div>
                                            <div class="text-end">
                                                <button [disabled]="addServiceResourceSelected == null" class="btn btn-primary" (click)="createServiceResourceElement()">
                                                    <span>{{ 'forms-itinerary.itinerary-item-create' | locTranslate }}</span>
                                                </button>
                                                <button [disabled]="addServiceResourceSelected == null || addServiceElementSelected == null" class="btn btn-primary ms-2" (click)="addServiceResourceElement()">
                                                    <span>{{ 'forms-itinerary.itinerary-item-add' | locTranslate }}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-pg-form>
        </div>
    </ng-container>

    <div *ngIf="createModalType != null" class="FormsItinerary-CreateModal">
        <div class="modal-header border-top border-bottom my-3 py-3">
            <h5 class="modal-title">{{ 'forms-itinerary.itinerary-item-create-'  + createModalType | locTranslate }} - {{ createModalResource }}</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="onCreateElementFormReturn({ action: 'cancel' })"></button>
        </div>
        <div class="modal-body">
            <app-pg-loading *ngIf="createModalData == null"></app-pg-loading>
            <ng-container *ngIf="createModalData != null">
                <app-forms-experience *ngIf="createModalResource == 'Experience'" [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateElementFormReturn($event)"></app-forms-experience>
                <app-forms-host *ngIf="createModalResource == 'Host'" [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateElementFormReturn($event)"></app-forms-host>
                <app-forms-eatery *ngIf="createModalResource == 'Eatery'" [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateElementFormReturn($event)"></app-forms-eatery>
                <app-forms-experience-suppplier *ngIf="createModalResource == 'ExperienceSupplier'" [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateElementFormReturn($event)"></app-forms-experience-suppplier>
                <app-forms-poi *ngIf="createModalResource == 'Poi'" [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateElementFormReturn($event)"></app-forms-poi>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #editStopModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'forms-itinerary.itinerary-item-edit-' + editStopModalMode | locTranslate }}</h4>
        <button type="button" class="modal-close btn btn-link" (click)="editStopModalRef.dismiss()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <app-pg-form [formLayout]="editStopLayout" [hasCancel]="true" 
            cancelText="{{ 'forms-itinerary.itinerary-item-edit-cancel' | locTranslate }}" cancelIcon="" (formCancel)="editStopModalRef.dismiss();" 
            submitText="{{ 'forms-itinerary.itinerary-item-edit-ok' | locTranslate }}" submitIcon="" (formSubmit)="editStopModalRef.close();"></app-pg-form>
    </div>
</ng-template>