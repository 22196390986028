<ng-container *ngIf="getDisplayViewType(); let viewType">
    <div class="PGFormField PGFormField--Type{{viewType.charAt(0).toUpperCase() + viewType.slice(1)}}" [ngClass]="{ 'PGFormField--NoLabel': noLabel }">
        <div class="form-group {{display && display.inlineLabel ? 'PGFormField--DisplayInlineLabel' : ''}}">
            <label [for]="fieldId" [style.display]="fieldData.hidden ? 'none' : null" class="{{viewType == 'info' ? 'alert alert-info' : ''}}">
                <ng-container *ngIf="fieldData.tooltip != null">
                    <small *ngIf="viewType != 'info'" class="form-text text-dark" [innerHTML]="fieldData.tooltip | locTranslate"></small>
                    <span *ngIf="viewType == 'info'" class="form-text" [innerHTML]="fieldData.tooltip | locTranslate"></span>
                </ng-container>
                <span *ngIf="viewType == 'select'">
                    <app-pg-select [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [resource]="fieldData.resource" [resourceSemantic]="fieldData.resourceSemantic" [options]="getFieldOptions()" [optionsFilter]="optionsFilter" [optionsValue]="fieldData.optionsValue" [multi]="fieldData.multi"
                        [searchMin]="fieldData.searchMin" [display]="display"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-select>
                </span>
                <span *ngIf="viewType == 'timetable-periods'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="periods" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                         [multi]="fieldData.multi"[ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'timetable-openings'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="openings" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'timetable-slots'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="slots" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'timetable-dates'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="dates" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'timetable-departures'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="departures" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                         [multi]="fieldData.multi"[ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'timetable-days'">
                    <app-pg-timetable-editor [fieldId]="fieldId" timetableMode="days" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [timezone]="fieldData.timezone"
                         [multi]="fieldData.multi"[ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-timetable-editor>
                </span>
                <span *ngIf="viewType == 'tickets' || viewType == 'tickets-host'">
                    <app-pg-tickets-editor [fieldId]="fieldId" [ticketsType]="viewType.split('-')[1]" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [availableLanguages]="getAvailableLanguages()" [selectedLanguage]="getSelectedLanguage()"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-tickets-editor>
                </span>
                <span *ngIf="viewType == 'survey'">
                    <app-pg-survey-editor [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [availableLanguages]="getAvailableLanguages()" [selectedLanguage]="getSelectedLanguage()"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-survey-editor>
                </span>
                <span *ngIf="viewType == 'rating'">
                    <app-pg-rating [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" 
                        [icon]="fieldData.icon"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-rating>
                </span>
                <span *ngIf="viewType == 'html'">
                    <app-pg-html-editor [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [htmlMode]="fieldData.htmlMode"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-html-editor>
                </span>
                <span *ngIf="viewType == 'json'">
                    <app-pg-json-editor [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()" [jsonType]="fieldData.jsonType">
                    </app-pg-json-editor>
                </span>
                <span *ngIf="viewType == 'object'">
                    <pre [id]="fieldId">{{getFieldValue()|json}}</pre>
                </span>
                <span *ngIf="viewType == 'text' || viewType == 'blob'">
                    <textarea [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [maxLength]="fieldData.maxLength" [placeholder]="(fieldData.placeholder || '') | locTranslate"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"></textarea>
                </span>

                <span *ngIf="viewType == 'string'" [ngClass]="{ 'input-group': fieldData.icon != null }">
                    <input [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [maxLength]="fieldData.maxLength" [placeholder]="(fieldData.placeholder || '') | locTranslate"
                        type="text"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"/>
                    <span *ngIf="fieldData.icon != null" class="input-group-text">
                        <i class="{{fieldData.icon}}"></i>
                    </span>
                </span>
                <span *ngIf="viewType == 'email'">
                    <input [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        type="text"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"/>
                </span>
                <span *ngIf="viewType == 'phone' || viewType == 'phone-international'">
                    <app-pg-phone [id]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [international]="viewType == 'phone-international'"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"></app-pg-phone>
                </span>
                <span *ngIf="viewType == 'recipient'">
                    <app-pg-recipient [id]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [compositeValue]="true"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"></app-pg-recipient>
                </span>
                <span *ngIf="viewType == 'integer'" [ngClass]="{ 'input-group': fieldData.icon != null }">
                    <input [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [min]="fieldData.min" [max]="fieldData.max" [attr.step]="fieldData.step"
                        type="string" format="integer"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"/>
                    <span *ngIf="fieldData.icon != null" class="input-group-text">
                        <i class="{{fieldData.icon}}"></i>
                    </span>
                </span>
                <span *ngIf="viewType == 'decimal' || viewType == 'float'" [ngClass]="{ 'input-group': fieldData.icon != null }">
                    <input [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [min]="fieldData.min" [max]="fieldData.max" [step]="step"
                        type="string" format="decimal"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"/>
                    <span *ngIf="fieldData.icon != null" class="input-group-text">
                        <i class="{{fieldData.icon}}"></i>
                    </span>
                </span>

                <span *ngIf="viewType == 'password'">
                    <input [id]="fieldId" class="form-control" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [maxLength]="fieldData.maxLength" 
                        type="password"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"/>
                </span>
                <span *ngIf="viewType == 'slider'">
                    <app-pg-slider [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [min]="fieldData.min" [max]="fieldData.max" [step]="fieldData.step" [scale]="fieldData.sliderScale"
                    [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()"></app-pg-slider>
                </span>
                <span *ngIf="viewType == 'boolean'">
                    <app-pg-switch [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [small]="display && display.inlineLabel"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-switch>
                </span>
                <span *ngIf="viewType == 'date'" class="PGFormField-Field--Small">
                    <app-pg-date-picker [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-date-picker>
                </span>
                <span *ngIf="viewType == 'time'" class="PGFormField-Field--Small">
                    <app-pg-time-picker [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-time-picker>
                </span>
                <span *ngIf="viewType == 'datetime'">
                    <app-pg-datetime-picker [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-datetime-picker>
                </span>
                <span *ngIf="viewType == 'location'">
                    <app-pg-location-picker [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [placeholder]="fieldData.placeholder | locTranslate"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()" [slaves]="getFieldSlaves()" (setSlaves)="setFieldSlaves($event);">
                    </app-pg-location-picker>
                </span>
                <span *ngIf="viewType == 'list'">
                    <app-pg-list-editor [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required"
                        [resource]="fieldData.resource" [options]="getFieldOptions()"
                        [searchMin]="fieldData.searchMin" [display]="display"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()">
                    </app-pg-list-editor>
                </span>
                <span *ngIf="viewType == 'file'">
                    <app-pg-file-picker [fieldId]="fieldId" [readonly]="fieldData.readonly || disabled" [required]="fieldData.required" [multi]="fieldData.multi" [fileType]="fieldData.fileType" [fileOptions]="fieldData.fileOptions"
                        [ngModel]="getFieldValue()" (ngModelChange)="setFieldValue($event); emitChange()" [groupId]="groupId" [realmId]="realmId">
                    </app-pg-file-picker>
                </span>
                <span *ngIf="viewType == 'split'">
                    <div class="border-bottom mb-2"></div>
                </span>

                <span class="PGFormField-Heading">
                    <ng-container *ngIf="fieldData.label != null">
                        <h5 *ngIf="viewType == 'info'" class="m-0">
                            <i class="fa-regular fa-info-circle me-2"></i>
                            <span [innerHTML]="fieldData.label | locTranslate"></span>
                        </h5>
    
                        <b *ngIf="viewType != 'info'" [innerHTML]="fieldData.label | locTranslate"></b>
                    </ng-container>

                    <span class="PGFormField-Status text-muted">
                        <span *ngIf="fieldData.required" class="PGFormField-Status-Required PGTooltip {{validationIssues != null && validationIssues.required != null ? 'text-danger' : ''}}">
                            <i class="fa-regular fa-asterisk fa-sm"></i>
                            <div *ngIf="fieldData.multi" class="PGTooltip-Content">Seleziona almeno un elemento</div>
                            <div *ngIf="!fieldData.multi" class="PGTooltip-Content">Il campo &egrave; obbligatorio</div>
                        </span>
                        <span class="PGFormField-Status-Other">
                            <span *ngIf="fieldData.maxLength != null || fieldData.warnLength != null" [ngClass]="{
                                'text-danger': validationIssues != null && validationIssues.maxLength != null,
                                'text-warning': warningIssues != null && warningIssues.warnLength != null,
                                'PGTooltip': warningIssues != null && warningIssues.warnLength != null && fieldData.warnMessage }"
                                [title]="getFieldValueLengthReal()">
                                {{getFieldValueLength()}}/{{fieldData.maxLength}}
                                <div *ngIf="warningIssues != null && warningIssues.warnLength != null && fieldData.warnMessage" class="PGTooltip-Content">
                                    {{fieldData.warnMessage}}
                                </div>
                            </span>
                            <span *ngIf="fieldData.warnIf != null && warningIssues != null && warningIssues.warnIf != null" class="PGTooltip">
                                <i class="text-warning fa fa-exclamation-triangle"></i>
                                <div *ngIf="warningIssues != null && warningIssues.warnIf != null" class="PGTooltip-Content">
                                    {{warningIssues.warnIf}}
                                </div>
                            </span>
                            <span *ngIf="fieldData.min != null && fieldData.min != max" class="{{validationIssues != null && (validationIssues.min != null || validationIssues.max != null) ? 'text-danger' : ''}}">
                                <b>MIN {{formatValue(fieldData.min)}} MAX {{formatValue(fieldData.max)}}</b>
                            </span>
                            <span *ngIf="fieldData.min != null && fieldData.min == max" class="{{validationIssues != null && validationIssues.min != null ? 'text-danger' : ''}}">
                                <b>MIN {{formatValue(fieldData.min)}}</b>
                            </span>
                            <span *ngIf="fieldData.max != null && fieldData.min == null" class="{{validationIssues != null && validationIssues.max != null ? 'text-danger' : ''}}">
                                <b>MAX {{formatValue(fieldData.max)}}</b>
                            </span>
                            <span *ngIf="fieldData.constraints != null" class="PGTooltip {{validationIssues != null && validationIssues.constraints != null ? 'text-danger' : ''}}">
                                <i class="fa-regular fa-link"></i>
                                <div class="PGTooltip-Content">
                                    <div *ngFor="let constraint of fieldData.constraints">{{formatConstraint(constraint)}}</div>
                                </div>
                            </span>
                        </span>
                    </span>
                    <span class="PGFormField-Actions">
                        <span *ngIf="display != null && display.info != null" class="PGFormField-Actions-Info PGTooltip">
                            <i class="fa-regular fa-info-circle text-muted"></i>
                            <span class="PGTooltip-Content" [innerHTML]="display.info"></span>
                        </span>
                        <ng-container *ngIf="fieldData.fieldActions != null">
                            <button *ngFor="let action of fieldData.fieldActions" class="btn btn-sm {{action.class}}" [title]="action.tooltip || ''" (click)="action.run(fieldData)" [disabled]="fieldData.readonly || disabled">
                                <i *ngIf="action.icon != null" class="{{action.icon}}"></i>
                                <span *ngIf="action.label != null">{{action.label | locTranslate}}</span>
                            </button>
                        </ng-container>
                        <span *ngIf="fieldData.locale" class="PGButtonBar PGFormField-Actions-Translations">
                            <button *ngFor="let language of getAvailableLanguages()" class="btn btn-sm btn-{{getSelectedLanguage() == language ? 'primary' : 'secondary'}}" (click)="setSelectedLanguage(language)">
                                {{language.split('_')[0].toUpperCase()}}
                            </button>
                        </span>
                    </span>
                </span>
            </label>
        </div>
    </div>
</ng-container>
