<div class="PGFileList PGFileList--{{getLayoutMode()}} {{selectionMode != null ? 'PGFileList--Selectable' : ''}}">
    <div class="PGFileList-Buttons pb-3">
        <div class="btn-group">
            <div *ngFor="let type of [null,'images','audios','videos','documents']" class="btn {{viewMode == type ? 'btn-primary' : 'btn-secondary' }}" (click)="setViewMode(type)">
                <i class="fa-regular {{getCategoryIcon(type)}}"></i>
                <span class="PGFileList-Buttons-Text">{{ 'pg-resource-view-file.type-' + (type || 'all') | locTranslate }}</span>
            </div>
        </div>

        <!-- TODO: QUA CI VANNO DRAFT? -->
        <button *ngIf="actions.create" class="btn btn-primary" (click)="createFile()">
            <i class="fa-regular fa-upload"></i>
            <span>{{ 'pg-resource-view-file.upload-resource' | locTranslate:{ resource: resourceName} }}</span>
        </button>
    </div>

    <div *ngIf="hasDirectoriesNavigation()" class="PGFileList-Navigation ps-2 ms-1">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <nav>
                <ol class="breadcrumb align-items-center mb-0">
                    
                    <li *ngIf="getFilterSearch(); let search" class="breadcrumb-item">
                        <i class="fa-regular fa-search me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-search' | locTranslate }} "{{search}}"</span>
                    </li>

                    <li *ngIf="getFilterTags(); let tags" class="breadcrumb-item">
                        <i class="fa-regular fa-hashtag me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-tags' | locTranslate }} "{{tags}}"</span>
                    </li>

                    <li *ngIf="getFilterSystemTags(); let system_tags" class="breadcrumb-item">
                        <i class="fa-regular fa-hashtag-lock me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-system_tags' | locTranslate }} "{{getSystemTagsLabel(system_tags)}}"</span>
                    </li>
                    
                    <ng-container *ngIf="!getFilterSearch() && !getFilterTags() && !getFilterSystemTags()">
                        <li class="breadcrumb-item">
                            /&nbsp;
                            <span *ngIf="currentDirectory == null">{{ 'pg-resource-view-file.directory-root' | locTranslate }}</span>
                            <a *ngIf="currentDirectory != null" href="javascript:void(0)" (click)="goToDirectory(null)">{{ 'pg-resource-view-file.directory-root' | locTranslate }}</a>
                        </li>
                        <li *ngFor="let directory of currentDirectoryPath" class="breadcrumb-item {{ directory == currentDirectory ? 'active' : '' }}">
                            <span *ngIf="directory == currentDirectory" class=" {{isCutDirectory(directory) ? 'opacity-50' : ''}}">{{directory.label}}</span>
                            <a *ngIf="directory != currentDirectory" class=" {{isCutDirectory(directory) ? 'opacity-50' : ''}}" href="javascript:void(0)" (click)="goToDirectory(directory)">{{directory.label}}</a>
                        </li>
                    </ng-container>
                </ol>
            </nav>
            
            <div *ngIf="!getFilterSearch() && !getFilterTags() && !getFilterSystemTags()" class="d-flex">
                <div>
                    <button class="btn btn-link" [disabled]="!canPasteIntoDirectory()" (click)="pasteIntoDirectory()">
                        <i class="fa-regular fa-paste"></i><span>{{ 'pg-resource-view-file.directory-paste' | locTranslate }}</span>
                    </button>
                </div>
                <div class="ms-2">
                    <button class="btn btn-link" (click)="setShowCreateDirectory(true)">
                        <i class="fa-regular fa-folder-plus"></i><span>{{ 'pg-resource-view-file.directory-create' | locTranslate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    <div class="bg-white PGFileList-Main">
        <div *ngIf="hasDirectoriesNavigation()" class="border-bottom PGFileList-Directory container-fluid">
            <div class="row pb-3">
                <div *ngIf="isLoadingDirectoryData" class="PGFileList-Directory-Loading mt-3">
                    <app-pg-loading [isOverlay]="currentDirectoryList != null"></app-pg-loading>
                </div>
                    
                <ng-container *ngIf="currentDirectoryList != null"> 
                    <div *ngIf="showCreateDirectory" class="PGFileList-Directory-Element col-12 col-md-6 col-lg-4 col-xl-3 pt-3">
                        <div class="d-flex align-items-center h-100">
                            <input #createDirectoryInput class="form-control form-control-sm" placeholder="{{ 'pg-resource-view-file.directory-create' | locTranslate }}" [(ngModel)]="createDirectoryLabel" (blur)="setShowCreateDirectory(false, true)">
                            <button class="btn btn-sm btn-primary ms-1" [disabled]="!canCreateDirectory()" (click)="createDirectory()">
                                <i class="fa-regular fa-plus"></i>
                            </button>
                        </div>
                    </div> 
    
                    <div *ngIf="!showCreateDirectory && currentDirectoryList.length == 0" class="PGFileList-Directory-NoDirectories col-12 pt-3 text-muted">
                        {{ 'pg-resource-view-file.directory-no-directories' | locTranslate }}
                    </div>
    
                    <div *ngFor="let directory of currentDirectoryList" class="PGFileList-Directory-Element col-12 col-md-6 col-lg-4 col-xl-3 pt-3">
                        
                        <div class="bg-light d-flex align-items-center">
                            <div class="PGFileList-Directory-Element-Display {{isCutDirectory(directory) ? 'opacity-50' : ''}}">
                                <i class="fa-regular fa-folder"></i>
                                <div class="PGFileList-Directory-Element-Display-Text">
                                    <div class="PGFileList-Directory-Element-Display-Text-Name">
                                        <a *ngIf="currentRenameDitectory != directory" href="javascript:void(0)" (click)="goToDirectory(directory)">{{directory.label}}</a>
                                        <div *ngIf="currentRenameDitectory == directory" class="pe-1">
                                            <input class="form-control form-control-sm" [(ngModel)]="currentRenameDitectoryLabel" (blur)="checkDoCurrentRenameDirectory()" (change)="checkDoCurrentRenameDirectory()">
                                        </div>
                                    </div>
                                    <!-- TODO: questo andrà inserito quando ci sarà la ricerca testuale
                                    <div class="PGFileList-Directory-Element-Display-Text-Path">
                                        <small class="text-muted">{{directory.pathLabels}}</small>
                                    </div>
                                    -->
                                </div>
                            </div>
                            <div>
                                <app-pg-resource-element-menu resourceId="Directory" [elementId]="directory.id" [elementData]="directory" [baseURL]="baseURL" (dataChange)="loadCurrentDirectory()"
                                    [customActions]="{ edit: { label: 'pg-resource-view-file.directory-rename', action: true } }" (customActionRun)="renameDirectory(directory)">
                                    <button class="dropdown-item btn" (click)="cutDirectory(directory)">
                                        <i class="fa-regular fa-cut"></i><span>{{ 'pg-resource-view-file.directory-cut' | locTranslate }}</span>
                                    </button>
                                </app-pg-resource-element-menu>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>   
        </div>

        <div class="PGFileList-Scrollable container-fluid">
            <div class="row pb-3">
                <div *ngIf="isAtEnd && dataRows.length == 0" class="PGFileList-Directory-NoFiles col-12 pt-3 text-muted">
                    {{ 'pg-resource-view-file.directory-no-files' | locTranslate }}
                </div>

                <div *ngFor="let file of dataRows" class="PGFileList-Element col-12 col-md-6 col-lg-4 col-xl-3 pt-3 pg-animation-slidedown {{file.progress != null ? 'PGFileList-Element--Uploading' : ''}} {{isSelected(file) ? 'PGFileList-Element--Selected shadow-highlight' : ''}}" [appPgAnimations]="file.deleted ? 'ExitT' : ''" (click)="toggleSelection(file)">
                    <div class="PGFileList-Element-Progress bg-light">
                        <app-pg-loading [progressVal]="file.progress" [hasError]="file.error"></app-pg-loading>
                    </div>
                    <div class="PGFileList-Element-Preview bg-light">
                        <div class="PGFileList-Element-Preview-Display {{isCutFile(file) ? 'opacity-50' : ''}}">
                            <app-pg-file-preview [fileData]="file" [viewMode]="viewMode"></app-pg-file-preview>
                        </div>
                        <div>
                            <app-pg-resource-element-menu [resourceId]="resourceId" [elementId]="file.id" [elementData]="file" [baseURL]="baseURL" (dataChange)="reset()">
                                <button *ngIf="hasDirectoriesNavigation() && actions.edit" class="dropdown-item btn" (click)="cutFile(file)">
                                    <i class="fa-regular fa-cut"></i><span>{{ 'pg-resource-view-file.file-cut' | locTranslate }}</span>
                                </button>
                                <button class="dropdown-item btn" (click)="downloadFile(file)">
                                    <i class="fa-regular fa-download"></i><span>{{ 'pg-resource-view-file.file-download' | locTranslate }}</span>
                                </button>
                            </app-pg-resource-element-menu>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="PGFileList-Footer">
                <app-pg-view-more [hidden]="isLoadingData || isAtEnd" (viewMore)="loadData()"></app-pg-view-more>
                <div *ngIf="isLoadingData" class="PGFileList-Footer-Loading pg-animation-fadein">
                    <app-pg-loading></app-pg-loading>
                </div>
            </div>
        </div>
    </div>
</div>