<div class="EFProduct {{ oneColumn ? 'EFProduct--OneColumn' : '' }}">
    <div *ngIf="hasBack" class="container EFProduct-GoBack pt-3">
        <div class="row">
            <div class="col">
                <button class="btn btn-light m-2" (click)="goBack.emit()">
                    <i class="fa-regular fa-chevron-left"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="container EFProduct-Main pt-3">
        <div class="row">
            <div class="col-12 position-relative">
                <div *ngIf="productData.cover != null" class="EFProduct-Cover card">
                    <app-ef-carousel [imagesList]="[productData.cover]"></app-ef-carousel>
                </div>

                <div *ngIf="productData.images != null && productData.images.length > 0" class="EFProduct-Images card">
                    <app-ef-carousel [imagesList]="productData.images"></app-ef-carousel>
                </div>
            </div>

            <div class="col-12">
                <div class="EFProduct-Translate small">
                    <div *ngIf="productData.isCurrentTranslationAutomatic()" class="d-flex justify-content-end align-items-center">
                        <span>{{ 'experience-finder.ef-product.button-translation-automatic' | locTranslate }}</span>
                        <button class="btn btn-link btn-sm p-0 ms-1" (click)="productData.forceNonAutomaticTranslation()">{{ 'experience-finder.ef-product.button-unautomatic' | locTranslate }}</button>
                    </div>

                    <div *ngIf="productData.isCurrentTranslationForced()" class="d-flex justify-content-end align-items-center">
                        <span>{{ 'experience-finder.ef-product.button-translation-forced' | locTranslate }}</span>
                        <button class="btn btn-link btn-sm p-0 ms-1" (click)="productData.unForceTranslation()">{{ 'experience-finder.ef-product.button-unforce' | locTranslate }}</button>
                    </div>

                    <!--<ng-container *ngIf="!productData.hasProductCurrentLanguage()">
                        <button *ngIf="productData.getRemoteTranslationStatus() != 'complete'" class="btn btn-sm btn-link bg-white" [disabled]="productData.getRemoteTranslationStatus() == 'loading'" (click)="productData.remoteTranslateProduct()">{{ 'experience-finder.ef-product.button-translate' | locTranslate }}</button>

                        <button *ngIf="productData.getRemoteTranslationStatus() == 'complete'" class="btn btn-sm btn-link bg-white" (click)="productData.unRemoteTranslateProduct()">{{ 'experience-finder.ef-product.button-untranslate' | locTranslate }}</button>
                    </ng-container>-->
                </div>
            </div>

            <div class="col-12 {{ !oneColumn ? 'col-md-7 col-lg-8' : '' }}">
                <div class="card">
                    <div class="card-body">
                        <div class="EFProduct-Category mb-3 d-flex h5">
                            <div class="badge text-white" [style.backgroundColor]="getTypeColor(productData.type)">
                                <i class="fa-regular" [innerHTML]="getTypeIcon(productData.type)"></i>
                                <span class="ms-2">{{productData.getTypeText()}}</span>
                            </div>
                            
                            <div *ngIf="productData.getCategoryText(); let text" class="badge bg-white border ms-3" [style.color]="getTypeColor(productData.type)" [style.borderColor]="getTypeColor(productData.type) + ' !important'">
                                <span [innerHTML]="text"></span>
                            </div>
                        </div>
                        <h3 [innerHTML]="productData.getTranslatedProductField('title')"></h3>
                    
                        <div *ngIf="productData.getTranslatedProductField('description'); let description" class="EFProduct-Description mb-3" [innerHTML]="description | safe:'html'" appExternalLinks></div>

                        <ng-container *ngFor="let additional_description of ['whats_included','services_description','special_dishes']">
                            <ng-container *ngIf="productData.getTranslatedProductField(additional_description); let description">
                                <h5 class="mb-3">{{ getAdditionalDescriptionLabel(additional_description) }}</h5>
                                <div class="EFProduct-Description mb-3" [innerHTML]="description | safe:'html'" appExternalLinks></div>
                            </ng-container>
                        </ng-container>
                    
                        <div *ngIf="productData.phone != null || productData.email != null || productData.website != null" class="mb-3">
                            <h5 class="mb-3">{{ 'experience-finder.app.product-contacts' | locTranslate }}</h5>
                            <div *ngIf="productData.phone != null" class="EFProduct-Contact">
                                <span class="EFProduct-Supplier-Contact-Label">
                                    {{ 'experience-finder.app.product-contacts-phone' | locTranslate }}
                                </span>
                                
                                <a *ngIf="!useContactsQR" href="tel:{{productData.phone}}">{{productData.phone}}</a> 
                                <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('tel:' + productData.phone)">{{productData.phone}}</a>

                                <a *ngIf="!useContactsQR" href="tel:{{productData.landline_number}}">{{productData.landline_number}}</a>
                                <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('tel:' + productData.landline_number)">{{productData.landline_number}}</a>
                            </div>
                            <div *ngIf="productData.email != null" class="EFProduct-Contact">
                                <span class="EFProduct-Supplier-Contact-Label">
                                    {{ 'experience-finder.app.product-contacts-email' | locTranslate }}
                                </span>
                    
                                <a *ngIf="!useContactsQR" href="mailto:{{productData.email}}">{{productData.email}}</a>
                                <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('mailto:' + productData.email)">{{productData.email}}</a>
                            </div>
                            <div *ngIf="productData.website != null" class="EFProduct-Contact">
                                <span class="EFProduct-Supplier-Contact-Label">
                                    {{ 'experience-finder.app.product-contacts-website' | locTranslate }}
                                </span>
                                
                                <a *ngIf="!useContactsQR" target="_blank" href="{{productData.website}}">{{productData.website}}</a>
                                <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink(productData.website)">{{productData.website}}</a>
                            </div>
                        </div>
                    
                        <div class="EFProduct-Classification">
                            <div *ngIf="productData.getDateDisplay(); let date">
                                <i class="fa-regular fa-calendar"></i>
                                <span>{{ 'experience-finder.app.product-date' | locTranslate }}</span>
                                <b [innerHTML]="date | safe:'html'"></b>
                            </div>

                            <div *ngIf="productData.getTimeDisplay(); let time">
                                <i class="fa-regular fa-clock"></i>
                                <span>{{ 'experience-finder.app.product-time' | locTranslate }}</span>
                                <b [innerHTML]="time | safe:'html'"></b>
                            </div>

                            <div *ngIf="POIData != null">
                                <i class="fa-regular fa-location-dot"></i>
                                <span>{{ 'experience-finder.app.product-location' | locTranslate }}</span>
                                <b>{{getTranslatedValue(POIData, 'name')}}</b>
                            </div>

                            <ng-container *ngIf="POIData == null">
                                <div *ngIf="formatProductLocation(true); let location">
                                    <i class="fa-regular fa-location-dot"></i>
                                    <span>{{ 'experience-finder.app.product-location' | locTranslate }}</span>
                                    <b [innerHTML]="location | safe:'html'"></b>
                                </div>
                            </ng-container>

                            <div *ngIf="productData.stars; let stars" class="EFProduct-Classification-Stars">
                                <i class="fa-regular fa-star"></i>
                                <span>{{ 'experience-finder.app.product-stars' | locTranslate }}</span>
                                <b>
                                    <small>
                                        <ng-container *ngFor="let i of [1,2,3,4,5]">
                                            <i *ngIf="i <= stars" class="fa-solid fa-star"></i>
                                            <i *ngIf="i > stars" class="fa-regular fa-star"></i>
                                        </ng-container>
                                    </small>
                                </b>
                            </div>

                            <div *ngIf="getOptionMappedArrayStringDisplay(productData.cuisine_type, productData.resource, 'cuisine_type'); let cuisine_type">
                                <i class="fa-regular fa-hat-chef"></i>
                                <span>{{ 'experience-finder.app.product-cuisine_type' | locTranslate }}</span>
                                <b>{{cuisine_type}}</b>
                            </div>

                            <div *ngIf="productData.duration; let duration">
                                <i class="fa-regular fa-stopwatch"></i>
                                <span>{{ 'experience-finder.app.product-duration' | locTranslate }}</span>
                                <b>{{duration | locFormat:'duration'}}</b>
                            </div>

                            <div *ngIf="getOptionMappedArrayStringDisplay(productData.target, productData.resource, 'target'); let target">
                                <i class="fa-regular fa-users"></i>
                                <span>{{ 'experience-finder.app.product-target' | locTranslate }}</span>
                                <b>{{target}}</b>
                            </div>

                            <div *ngIf="getTagsDisplay(productData.tags); let tags">
                                <i class="fa-regular fa-hashtag"></i>
                                <span>{{ 'experience-finder.app.product-tags' | locTranslate }}</span>
                                <b>{{tags}}</b>
                            </div>

                            <div *ngIf="getLanguagesDisplay(productData.languages); let languages">
                                <i class="fa-regular fa-globe"></i>
                                <span>{{ 'experience-finder.app.product-languages' | locTranslate }}</span>
                                <b [innerHTML]="languages | safe:'html'"></b>
                            </div>
                    
                            <div *ngIf="productData.mean_of_transport; let transport">
                                <i class="fa-regular fa-walking"></i>
                                <span>{{ 'experience-finder.app.product-mean_of_transport' | locTranslate }}</span>
                                <b>
                                    <ng-container *ngFor="let item of transport; let i = index">
                                        <ng-container *ngIf="i > 0">, </ng-container> {{ 'OPTIONMAPS.Itinerary.mean_of_transport.' + item | locTranslate }}
                                    </ng-container>
                                </b>
                            </div>
                            <div *ngIf="productData.travel_distance; let travel_distance">
                                <i class="fa-regular fa-road"></i>
                                <span>{{ 'experience-finder.app.product-travel_distance' | locTranslate }}</span>
                                <b>{{travel_distance}}</b>
                            </div>
                            <ng-container *ngIf="!productData.duration">
                                <div *ngIf="productData.travel_time; let travel_time">
                                    <i class="fa-regular fa-stopwatch"></i>
                                    <span>{{ 'experience-finder.app.product-travel_time' | locTranslate }}</span>
                                    <b>{{travel_time}}</b>
                                </div>
                            </ng-container>

                            <div *ngIf="productData.getDateDisplay(true); let availability" class="EFProduct-Classification-Availability">
                                <i class="fa-regular fa-circle-check"></i>
                                <span>{{ 'experience-finder.app.product-availability' | locTranslate }}</span>
                                <b>
                                    <span [innerHTML]="availability | safe:'html'"></span>
                                    <div *ngIf="hasAvailabilityDetails(productData.availability)" class="EFProduct-Classification-Availability-Details">
                                        <button class="btn btn-link p-0" (click)="openAvailabilityDetails(productData.availability)">{{ 'experience-finder.app.product-availability-hours' | locTranslate }}</button>
                                    </div>
                                </b>
                            </div>     
                        </div> 

                        <div *ngIf="productData.services != null" class="EFProduct-Services mt-3">
                            <div *ngFor="let list of productData.services | keyvalue" class="mb-3">
                                <h5 class="mb-1">{{ getServiceTypeLabel(list.key) }}</h5>
                                <div>{{ getServiceListDisplay(list.value, list.key) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 {{ !oneColumn ? 'col-md-5 col-lg-4' : '' }} EFProduct-Buy-Container">
                <div class="card EFProduct-Buy">
                    <div class="card-body">
                        <div *ngIf="productData.accommodations != null" class="col-12">
                            <h4 class="mb-3">{{ 'experience-finder.ef-product.cost' | locTranslate }}</h4>
                            <div *ngFor="let accommodation of productData.accommodations; let i = index" class="mb-3 EFProduct-Accommodation">
                                
                                <h5 class="mb-1" [innerHTML]="productData.getTranslatedProductFieldArrayProperty('accommodations', i, 'accommodation_description')"></h5>

                                <div *ngIf="accommodation.accommodation_room_services != null" class="EFProduct-Services">
                                    <span>{{getOptionMappedArrayStringDisplay(accommodation.accommodation_room_services, 'Host', 'room_services')}}</span>
                                </div>

                                <div *ngIf="accommodation.accommodation_appartaments_services != null" class="EFProduct-Services">
                                    <span>{{getOptionMappedArrayStringDisplay(accommodation.accommodation_appartaments_services, 'Host', 'appartaments_services')}}</span>
                                </div>

                                <div *ngIf="getCostStartingAtDisplay(accommodation.cost_range); let cost_starting" class="mb-3 EFProduct-Buy-Price">
                                    <div>{{ 'experience-finder.ef-product.cost-starting-from' | locTranslate }}</div>
                                    <div>
                                        <h5 class="m-0 text-success" [innerHTML]="cost_starting | safe:'html'"></h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="productData.accommodations == null">
                            <ng-container *ngIf="getCostStartingAtDisplay(productData.cost_list); let cost_starting">
                                <h4 class="mb-3">{{ 'experience-finder.ef-product.cost' | locTranslate }}</h4>
                                <div class="mb-3 EFProduct-Buy-Price">
                                    <div>{{ 'experience-finder.ef-product.cost-starting-from' | locTranslate }}</div>
                                    <div>
                                        <h5 class="m-0 text-success" [innerHTML]="cost_starting | safe:'html'"></h5>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div *ngIf="hasProductMenuUrl()" class="d-flex justify-content-start">
                            <div *ngIf="getProductMenuUrl(); let menu" class="mb-3 me-3">
                                <a target="_blank" [href]="menu | safe:'url'">
                                    <span class="me-2">{{ 'experience-finder.ef-product.menu-file' | locTranslate }}</span>
                                    <i class="fa-regular fa-book-open"></i>
                                </a>
                            </div>
                        
                            <div *ngIf="getProductMenuUrl2(); let menu" class="mb-3 me-3">
                                <a target="_blank" [href]="menu | safe:'url'">
                                    <span class="me-2">{{ 'experience-finder.ef-product.menu-file-2' | locTranslate }}</span>
                                    <i class="fa-regular fa-book-open"></i>
                                </a>
                            </div>
                        </div>

                        <div *ngIf="productData.menu != null">
                            <div *ngFor="let item of productData.menu; let i = index" class="EFProduct-MenuItem mb-3">
                                <div>
                                    <h5 class="d-flex mb-1">
                                        <span [innerHTML]="productData.getTranslatedProductFieldArrayProperty('menu', i, 'menu_name')"></span>
                                        <span class="text-nowrap text-success">{{item.menu_price}} &euro; {{item.menu_price_unit}}</span>
                                    </h5>
    
                                    <div class="text-muted EFProduct-MenuItem-Description mb-1" [innerHTML]="productData.getTranslatedProductFieldArrayProperty('menu', i, 'menu_description')"></div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="getCostRangeDisplay(productData.cost_range); let cost_range" class="d-flex justify-content-between">
                            <h4 class="mb-3">{{ 'experience-finder.ef-product.cost-range' | locTranslate }}</h4>
                            <div class="mb-3 EFProduct-Buy-Price">
                                <h5 class="m-0" [innerHTML]="cost_range | safe:'html'"></h5>
                            </div>
                        </div>

                        <div *ngIf="getPaymentMethodDisplay(); let payment_method" class="mb-3 EFProduct-Buy-PaymentMethod text-success">{{ payment_method }}</div>

                        <ng-container  *ngIf="productData.isBookable()">
                            <ng-container *ngIf="getCheckoutURL(); let url">
                                <button *ngIf="useBookingQR" class="btn btn-primary btn-block btn-lg mt-0" (click)="showBookingQR(url)">
                                    {{ 'experience-finder.ef-product.button-book-now' | locTranslate }}
                                </button>
                                <a *ngIf="!useBookingQR" class="btn btn-primary btn-block btn-lg mt-0" target="_blank" href="{{url}}">
                                    {{ 'experience-finder.ef-product.button-book-now' | locTranslate }}
                                </a>
                            </ng-container>
                        </ng-container>

                        <!--<button class="btn btn-outline-primary btn-block btn-lg mt-0">{{ 'experience-finder.ef-product.button-add-calendar' | locTranslate }}</button>-->
                    </div>
                </div>
            </div>

            <div *ngIf="supplierData != null && !supplierData.error" class="col-12">
                <div class="EFProduct-Supplier card">
                    <div class="card-body">
                        <div class="row">
                            <div *ngIf="supplierData.logo != null && supplierData.logo != ''" class="col-4">
                                <img  class="EFProduct-Supplier-Image" [src]="supplierData.logo"/>
                            </div>
                            <div class="{{ supplierData.logo != null && supplierData.logo != '' ? 'col-8' : 'col-12'}}">
                                <h5 class="mb-3">{{supplierData.name}}</h5>
                
                                <div *ngIf="supplierData.phone != null" class="EFProduct-Supplier-Contact">
                                    <span class="EFProduct-Supplier-Contact-Label">
                                        {{ 'experience-finder.app.product-contacts-phone' | locTranslate }}
                                    </span>
                                    
                                    <a *ngIf="!useContactsQR" href="tel:{{supplierData.phone}}">{{supplierData.phone}}</a>
                                    <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('tel:' + supplierData.phone)">{{supplierData.phone}}</a>

                                    <a *ngIf="!useContactsQR" href="tel:{{supplierData.landline_number}}">{{supplierData.landline_number}}</a>
                                    <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('tel:' + supplierData.landline_number)">{{supplierData.landline_number}}</a>
                                </div>
                                <div *ngIf="supplierData.email != null" class="EFProduct-Supplier-Contact">
                                    <span class="EFProduct-Supplier-Contact-Label">
                                        {{ 'experience-finder.app.product-contacts-email' | locTranslate }}
                                    </span>
                
                                    <a *ngIf="!useContactsQR" href="mailto:{{supplierData.email}}">{{supplierData.email}}</a>
                                    <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('mailto:' + supplierData.email)">{{supplierData.email}}</a>
                                </div>
                                <div *ngIf="supplierData.website != null" class="EFProduct-Supplier-Contact">
                                    <span class="EFProduct-Supplier-Contact-Label">
                                        {{ 'experience-finder.app.product-contacts-website' | locTranslate }}
                                    </span>
                                    
                                    <a *ngIf="!useContactsQR" target="_blank" href="{{supplierData.website}}">{{supplierData.website}}</a>
                                    <a *ngIf="useContactsQR" href="javascript:void(0)" (click)="openExternalLink('mailto:' + supplierData.website)">{{supplierData.website}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="hasProductMap()" class="col-12 EFProduct-Map">
                <app-ef-product-map [productData]="productData" [disabled]="true" (showStop)="onShowStop($event)"></app-ef-product-map>
            </div>
        </div>


        <!-- TODO: DA SISTEMARE, da qui in poi  -->

        <div class="row">
        
            <div *ngIf="showcaseElements != null" class="col-12 EFProduct-Showcase mb-3">
                <app-pg-showcase [showcaseElements]="showcaseElements"></app-pg-showcase>
            </div>
            
            <div *ngIf="productData.hasParent()" class="col-12 EFProduct-Parent my-3">
                <h5 class="mb-3">{{ 'experience-finder.ef-product.parent-' + productData.type | locTranslate }}</h5>
                <app-pg-loading *ngIf="isLoadingParent"></app-pg-loading>
                <div *ngIf="!isLoadingParent">
                    <app-ef-product-card [productData]="parentProductData" (showProduct)="onShowProduct($event)"></app-ef-product-card>
                </div>
            </div>
            
            <ng-container *ngIf="productData.canHaveChildren()">
                <app-pg-loading *ngIf="isLoadingChildren"></app-pg-loading>
                <div *ngIf="!isLoadingChildren && childrenProductData != null && childrenProductData.length > 0" class="col-12 EFProduct-Children mt-3">
                    <h5 class="mb-0">{{ 'experience-finder.ef-product.children-' + productData.type | locTranslate }}</h5>
                    <div>
                        <app-ef-list [oneItem]="true" [productsData]="childrenProductData" (showProduct)="onShowProduct($event)"></app-ef-list>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #modalAvailabilityDetails>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'experience-finder.app.product-availability' | locTranslate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeAvailabilityDetails()"></button>
    </div>
    <div class="modal-body">
        <ng-container *ngFor="let item of modalAvailabilityDetailsTarget">
            <div *ngIf="isAvailabilityValid(item)">
                <table class="table">
                    <thead>
                        <tr>
                            <th [attr.colspan]="7">
                                <h6 *ngIf="item.days.length == 0" class="m-0">{{ 'pg-timetable-editor.all-year' | locTranslate}}</h6>
                                <h6 *ngFor="let day of item.days; let i = index" class="m-0">
                                    <ng-container *ngIf="i > 0">, </ng-container>
                                    <ng-container *ngIf="isAvailabilityDayAllYear(day)">
                                        {{ 'pg-timetable-editor.all-year' | locTranslate }}
                                    </ng-container>
                                    <ng-container *ngIf="!isAvailabilityDayAllYear(day)">
                                        {{ getAvailabilityDayDates(day) }}
                                    </ng-container>
                                </h6>
                            </th>
                        </tr>
                        <tr>
                            <th *ngFor="let i of [0,1,2,3,4,5,6]" class="text-center">
                                {{getDayName(i)}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngIf="item.unavailable" [attr.colspan]="7">
                                <span class="text-danger">{{ 'pg-timetable-editor.unavailable-period' | locTranslate }}</span>
                            </td>
                            <ng-container *ngIf="!item.unavailable">
                                <td *ngFor="let i of [0,1,2,3,4,5,6]" class="text-center pb-0">
                                    <div *ngFor="let hour of item.hours" class="pb-2">
                                        <div *ngIf="getDayStatus(hour, i)">{{ hour.begin }}<ng-container *ngIf="productData.getAvailabilityType() == 'timetable-openings'"><br>{{ hour.end }}</ng-container></div>
                                        <div *ngIf="!getDayStatus(hour, i)">&nbsp;<ng-container *ngIf="productData.getAvailabilityType() == 'timetable-openings'"><br>&nbsp;</ng-container></div>
                                    </div>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #modalExternalLink>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'experience-finder.ef-product.modal-external-link-title' | locTranslate }}</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">{{ 'experience-finder.ef-product.modal-external-link-text' | locTranslate }}</p>
        <div>
            <app-pg-qrcode [text]="modalExternalLinkTarget"></app-pg-qrcode>
        </div>
        <div class="text-center mt-3">
            <button class="btn btn-primary" (click)="closeExternalLink()">{{ 'experience-finder.ef-product.modal-external-link-close' | locTranslate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #modalBookingQR>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'experience-finder.ef-product.modal-booking-qr-title' | locTranslate }}</h5>
    </div>
    <div class="modal-body">
        <p class="text-center">{{ 'experience-finder.ef-product.modal-booking-qr-text' | locTranslate }}</p>
        <div>
            <app-pg-qrcode [text]="modalBookingQRTarget"></app-pg-qrcode>
        </div>
        <div class="text-center mt-3">
            <button class="btn btn-primary" (click)="hideBookingQR()">{{ 'experience-finder.ef-product.modal-booking-qr-close' | locTranslate }}</button>
        </div>
    </div>
</ng-template>

<ng-template #modalShowStop>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'experience-finder.ef-product.modal-show-stop-title' | locTranslate }}</h5>
    </div>
    <div class="modal-body EFProduct-Modal-ShowStop">
        <div>
            <app-ef-itinerary-stop-card [stopData]="modalShowStopData" [disabled]="true" [full]="true"></app-ef-itinerary-stop-card>
        </div>

        <div class="text-center mt-3">
            <button class="btn btn-primary" (click)="hideShowStop()">{{ 'experience-finder.ef-product.modal-show-stop-close' | locTranslate }}</button>
        </div>
    </div>
</ng-template>