import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormLayout } from '../../../models/form.model';
import { StepFormBase } from '../step-form-base';

@Component({
  selector: 'app-forms-experience-supplier',
  templateUrl: './forms-experience-supplier.component.html',
  styleUrls: ['./forms-experience-supplier.component.scss']
})
export class FormsExperienceSupplierComponent extends StepFormBase {

    formId = 'experience-supplier';
    resourceId = 'ExperienceSupplier';

    createFormFlow() {
        return new FormFlow({
            'dati_generali': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'is_broker', default: false, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'broker_code', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'company_name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'landline_number' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'phone' }),
                    new PgFormField({ label: 'auto', type: 'email', name: 'email', required: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'store_url' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'tags', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
                    new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', placeholder: 'auto',
                        slaves: {
                            'country': 'country',
                            'region': 'region',
                            'province': 'province',
                            'city': 'city',
                            'address': 'address',
                            'zipcode': 'zipcode',
                        }
                    }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
                ])
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', name: 'category', required: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@ExperienceSupplierTranslation', required: true, locale: true, tooltip: 'auto', maxLength: 1500 }),
                ])
            },
            'store_showcase': {
                title: null,
                description: 'auto',
                multi: 'store_showcase',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'string', name: 'showcase_title', required: true, locale: true }),
                    new PgFormField({ label: 'auto', type: 'file', name: 'showcase_image', fileType: 'images', required: true }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'showcase_description', locale: true }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: false, fileType: 'images', name: 'logo' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                ])
            }
        })
    }

    customizeFormFlow() {
        for(let _cField of ['country','region','province','city','address','zipcode']) {
            this.formFlow.states['dati_generali'].form.getFieldByName(_cField).visible = false;
        }
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        let _addressComponents = this.parseAddressComponents(data.address_components)

        return {
            country: _addressComponents.country,
            region: _addressComponents.region,
            province: _addressComponents.province,
            city: _addressComponents.city,
            address: _addressComponents.address,
            zipcode: _addressComponents.zipcode,
            website: data.website,
            landline_number: data.international_phone_number,
            name: data.name,
            geolocation: { 
                coordinates: [data.geometry.location.lat(), data.geometry.location.lng()], 
                type: 'Point' 
            }
        }
    }
}
